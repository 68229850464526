import React, { Component } from 'react'
// import moment from 'moment'
// import 'moment/locale/fi'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert
} from 'reactstrap'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Form from '@rjsf/material-ui'
// import TextWidget from '@rjsf/material-ui'
// import TextWidget from '@rjsf/material-ui/TextWidget'
import TextField from '@material-ui/core/TextField'
// import moment from 'moment'
// import 'moment/locale/fi'
import ImageSelectDialog from './ImageSelectDialog'
import TinyEditor from '../Editor/TinyEditor'
import { Typography } from '@material-ui/core'

const getDifference = (a, b) => 
  Object.entries(b).filter(([key, val]) => a[key] !== val && key in a).reduce((a, [key, v]) => ({...a, [key]: v}), {});

const CustomTimeWidget = (props) => {
  // console.log(props)
  // delete props.uiSchema
  // delete props.readonly
  // delete props.formContext
  return (
    <TextField
      type='time'
      onChange={(event) => props.onChange(event.target.value)}
      InputLabelProps={{
        shrink: true,
      }}
      value={(props.value || '04:00')}
      label={(props.label || '')}
    />
  );
  // return (
  //   <TextField
  //   id='starttime'
  //   label='Alkuaika'
  //   type='time'
  //   onChange={(e) =>
  //     this.setReservationTime('start', e.target.value)
  //   }
  //   defaultValue={
  //     this.state.selectedResource
  //       ? moment(this.state.selectedResource.start).format(
  //           'HH:mm'
  //         )
  //       : moment().format('HH:mm')
  //   }
  //   InputLabelProps={{
  //     shrink: true,
  //   }}
  // />
  // );
}

const CustomPersonWidget = (props) => {
  // console.log(props)
  return (
    <Autocomplete
      multiple
      options={props.searchable || []}
      getOptionLabel={props.handleOptionLabel} // {option => option.name}
      onChange={props.handleParticipant}
      getOptionSelected={(option, val) => {
        return option.id === val.id
      }}
      value={props.persons || []}
      onInputChange={(event, newInputValue) => {
        if (newInputValue.length > 2) {
          props.search(newInputValue).map((p) => p.item)
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant='standard'
          label='Lisää henkilö'
          placeholder='Henkilöt'
          margin='normal'
          fullWidth
        />
      )}
    />
  )
}


const CustomImageWidget = (props) => {
  return (
    <>
      <ImageSelectDialog  {...props} images={props.schema.items.images} value={props.value} label={props.label} onChange={props.onChange} />
    </>
    
  )
}

const CustomEditorWidget = (props) => {
  return (
    <>
      <Typography variant='caption'>{props.label}</Typography>
      <TinyEditor {...props} onChange={props.onChange} disableSave={true} />
    </>
  )
}
      

const widgets = {
  timeWidget: CustomTimeWidget,
  personWidget: CustomPersonWidget,
  imageWidget: CustomImageWidget,
  editorWidget: CustomEditorWidget
};


class FalconModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: props.isOpen,
      formData: this.props.resource,
      inputerror: false
    }
  }

  componentDidMount() {
    if (this.props.resource) {
      // console.log('Adding form data')
      this.setState({ formData: this.props.resource })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      if (this.props.isOpen) {
        this.setState({ isOpen: this.props.isOpen, formData: this.props.resource })
      } else {
        this.setState({ isOpen: this.props.isOpen })
      }
    }
  }

  clearState = () => {
    this.setState({ formData: {} }, this.props.toggle)
  } 

  onSave = (event) => {
    event.preventDefault()
    const data = this.state.formData
    // delete data.id
    if (data.id) {
      delete data.actions
      // console.log(data)
      this.props.onUpdate(data).then((dataSnapshot) => {
        this.clearState()
        if(this.props.modifiedCallback) this.props.modifiedCallback()
      }).catch((error) => console.error(error))
    } else {
      // console.log(this.props.onCreate)
      this.props.onCreate(data).then((dataSnapshot) => {
        this.clearState()
        if(this.props.modifiedCallback) this.props.modifiedCallback()
      })
    }
  }

  onFormChange = (formData) => {
    const diffs = getDifference(this.state.formData, formData)
    this.setState({ formData: formData })
    if (this.props.validate) {
      this.props.validate(diffs).then((result) => {
        // console.log(result)
        this.setState({ inputerror: false, errorAlert: <></> })
      }).catch((error) => {
        console.error('Error', error)
        this.setState({ inputerror: true, errorAlert: <Alert color='danger'>{error.error}</Alert> })
      })
    }
  }

  render() {
    const isDisabled = this.state.formData && this.state.formData.hasOwnProperty('editable') && !this.state.formData.editable
    // if (this.state.formData) {
    //   console.log(this.state.formData.hasOwnProperty('editable'))
    // }
    return (
      <div>
        <Modal
          isOpen={this.state.isOpen}
          toggle={this.props.toggle}
         size={this.props.size || 'md'}
        >
          <ModalHeader toggle={(this.props.cancel ? this.props.cancel : this.props.toogle)}>
            {this.props.title}
          </ModalHeader>
          <ModalBody>
            {this.state.errorAlert}
            <Form
              schema={this.props.dataSchema}
              uiSchema={this.props.uiSchema}
              formData={this.state.formData}
              onChange={e => this.onFormChange(e.formData)}
              widgets={widgets}
              disabled={isDisabled}
            >
              <Button color='success' disabled={this.state.inputerror} onClick={this.onSave}>
                Tallenna
              </Button>
            </Form>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </Modal>
      </div>
    )
  }
}

export default FalconModal
