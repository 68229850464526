import React from 'react'
import { withFirebase } from '../../components/Fb'

// reactstrap components
import {
  Row,
  Col
} from 'reactstrap'
import ConfirmationMessageTemplates from '../../components/Config/ConfirmationMessageTemplates'
import ReminderTemplates from '../../components/Config/ReminderTemplates'
import MessagingConfig from '../../components/Config/MessagingConfig'
// import AdminNotificationConfig from '../../components/Config/AdminNotifivationConfig'

// core components

class ConfigMessageTemplates extends React.Component {
  render () {
    return (
      <>
        <div className='content'>
          <Row>
            <Col md='12'>
              <ConfirmationMessageTemplates { ...this.props } />
            </Col>
          </Row>
          <Row>
            <Col md='12'>
              <ReminderTemplates { ...this.props } />
            </Col>
          </Row>
          <Row>
            <Col md='12'>
              <MessagingConfig { ...this.props } />
            </Col>
          </Row>
          {/* <Row>
            <Col md='12'>
              <AdminNotificationConfig { ...this.props } />
            </Col>
          </Row> */}
        </div>
      </>
    )
  }
}

export default withFirebase(ConfigMessageTemplates)
