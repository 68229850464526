/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Redirect, Route, Switch } from "react-router-dom";
// import { withAuthentication } from '../components/Session';

import AdminNavbar from "../components/Navbars/AdminNavbar.jsx";
import Footer from "../components/Footer/Footer.jsx";
import Sidebar from "../components/Sidebar/Sidebar.jsx";
// import FixedPlugin from "components/FixedPlugin/FixedPlugin.jsx";
import { withFirebase } from "../components/Fb/context.js";
import { Alert, Container } from 'reactstrap'

import getProfileRoutes from "../routes.js";
import subroutes from "../subroutes.js";

const CURRENT_VERSION = '1.3.3'

var ps;

function FadingRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={routeProps => (
        <Component {...routeProps} {...rest} />
      )}
    />
  );
}

class CashierLayout extends React.Component {
  constructor(props) {
    super(props);
    this.mainPanel = React.createRef()
    this.state = {
      backgroundColor: "black",
      activeColor: "info",
      sidebarMini: false, 
      routes: getProfileRoutes(this.props.profile.role),
      news: []
    };
  }

  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(this.mainPanel.current);
    }
    // console.log('checking version 1')
    this.props.firebase.listenUiVersionVenue('prod').on('value', (snapshot) => {
      // console.log('checking version 2')
      // console.log(snapshot.exists())
      if (snapshot && snapshot.exists()) {
        if (snapshot.val().version !== CURRENT_VERSION) {
          this.setState({ uiversionAlert: <Container fluid={this.props.fluid ? true : false}><Alert color="danger" style={{ marginTop: 80 }}>Uusi versio {CURRENT_VERSION} julkaistu. Päivitä sulkemalla sivu ja avaamalle se uudelleen. Jos sulkeminen ei auta, niin paina Vaihto-näppäin pohjassa sivun uudelleenlatausta.</Alert></Container> })
        }
        if (snapshot.val().news) {
          const news = Object.keys(snapshot.val().news).map((x) => snapshot.val().news[x])
          console.log('setting news', news)
          this.setState({ news })
        }
        if(snapshot.val().videohelp) {
          const videohelp = snapshot.val().videohelp
          this.setState({ videohelp })
        }
      }
    })

    this.props.firebase.getCustomerRef().get().then((customer) => {
      if (customer.exists) {
        const _dn = customer.data().displayName ? customer.data().displayName : customer.data().name ? customer.data().name : ''
        if (_dn) {
          this.setState({ displayName: _dn })
        }
      } else {
        this.setState({ displayName: '' })
      }
    }
    )
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    }
  }
  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.mainPanel.current.scrollTop = 0;
    }
  }

  getCompProps = (type, properties) => {
    var Type = type
    return <Type {...properties} />;
  }

  getComp = (type) => {
    var Type = type
    return <Type />;
  }

  getSubRoutes = subroutes => {
    return subroutes.map((prop, key) => {
      return (
        <FadingRoute
          path={prop.layout + prop.path}
          component={prop.component}
          key={key}
          {...this.props}
        />
      )
    })
  }

  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/cashier" || prop.layout === "/partner" || prop.layout === "/cooperative") {
        // var c = this.getComp(prop.component)
        // console.log(c)
        // console.log('Adding FadingRoute ' + prop.component)
        return (
          <FadingRoute
          path={prop.layout + prop.path}
          component={prop.component}
          key={key}
          {...this.props}
          {...prop.properties}
          />
        );
      } else {
        return null;
      }
    });
  };
  handleActiveClick = color => {
    this.setState({ activeColor: color });
  };
  handleBgClick = color => {
    this.setState({ backgroundColor: color });
  };
  handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      this.setState({ sidebarMini: false });
    } else {
      this.setState({ sidebarMini: true });
    }
    document.body.classList.toggle("sidebar-mini");
  }
  updateLastread = () => {
    this.props.firebase.updateLastread()
  }
  render() {
    console.log('CashierLayout', this.props)
    const profileRoutes = this.state.routes
    if(profileRoutes) {
      // console.log(profileRoutes)
    return (
      <div className="wrapper">
        <Sidebar
          {...this.props}
          routes={profileRoutes}
          bgColor={this.state.backgroundColor}
          activeColor={this.state.activeColor}
          displayName={this.state.displayName}
        />
        <div className="main-panel" ref={this.mainPanel} id="mainPanel">
          <AdminNavbar {...this.props} routes={profileRoutes} handleMiniClick={this.handleMiniClick} news={this.state.news} lastread={this.state.lastread} updateLastread={this.updateLastread} videohelp={this.state.videohelp} />
          {this.state.uiversionAlert}
          <Switch {...this.props} >
            {this.getRoutes(profileRoutes)}
            {this.getSubRoutes(subroutes)}
            {!this.props.user ? <Redirect to='/auth/login' /> : null}
          </Switch>
          {// we don't want the Footer to be rendered on full screen maps page
          this.props.location.pathname.indexOf("full-screen-map") !==
          -1 ? null : (
                <Footer fluid version={CURRENT_VERSION}/>
            )}

        </div>
         {// we don't want the Footer to be rendered on full screen maps page
          this.props.location.pathname === '/' ? this.props.location.pathname = '/admin/dashboard' : null
         }
      </div>
    );
  }
  else {
    return (<></>)
  }
  }
}

export default withFirebase(CashierLayout);
