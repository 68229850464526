import React from 'react'
import moment from 'moment'
import 'moment/locale/fi'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  // CardFooter,
  // Form,
  Input,
  Container,
  FormGroup,
  Label,
  Col,
  Row,
  Spinner,
  Collapse,
} from 'reactstrap'
import { withFirebase } from '../components/Fb'

// import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import TextField from '@material-ui/core/TextField'
// import { withAuthentication } from '../components/Session'

class Details extends React.Component {
  constructor(props) {
    super(props)
    // this.onSubmit = this.onSubmit.bind(this)
    this.state = {
      loading: true,
      updated: false,
    }
  }

  setStartDate = (event) => {
    // console.log(event.target.value)
    this.setState({ date: moment(event.target.value) })
  }

  setEndtime = (event) => {
    this.setState({ end: event.target.value })
  }

  setStarttime = (event) => {
    this.setState({ start: event.target.value })
  }

  setTitle = (event) => {
    this.setState({ title: event.target.value })
  }

  setDescription = (event) => {
    this.setState({ description: event.target.value })
  }

  setComment = (event) => {
    this.setState({ comment: event.target.value, updated: true })
  }

  setChecked = (event) => {
    if (this.state.away) {
      this.setState({
        away: !this.state.away,
        checked: !this.state.checked,
        updated: true,
      })
    } else {
      this.setState({ checked: !this.state.checked, updated: true })
    }
  }

  setAway = (event) => {
    if (this.state.checked) {
      this.setState({
        away: !this.state.away,
        checked: !this.state.checked,
        updated: true,
      })
    } else {
      this.setState({ away: !this.state.away, updated: true })
    }
  }

  onSave = (event) => {
    event.preventDefault()
    // console.log('Save: ' + this.props.computedMatch.params.id)
    const now = moment().unix()
    const work = {
      checked: this.state.checked,
      away: this.state.away === 'undefined' ? false : this.state.away,
      comment: this.state.checked ? '' : this.state.comment,
      start: this.state.data.start,
      end: this.state.data.end,
      log: this.state.data.log,
      message: this.state.data.message,
      task: this.state.data.task,
      type: this.state.data.type,
    }
    if (!work.log) work.log = {}
    work.log[now] = { timestamp: now, type: 'edit', user: 'user' }
    // console.log(work)
    this.props.firebase
      .addWork(
        work,
        this.props.profile.customerIds[0],
        this.props.computedMatch.params.id
      )
      .then((dataSnapshot) => {
        this.props.history.push('/user/main')
      })
  }

  componentDidMount() {
    document.body.classList.toggle('login-page')
    // console.log(
    //   'id: ' +
    //     this.props.computedMatch.params.id +
    //     ', user: ' +
    //     this.props.user.uid
    // )
    this.props.firebase
      .getWorkById(
        this.props.profile.customerIds[0],
        this.props.computedMatch.params.id
      )
      .then((dataSnapshot) => {
        if (dataSnapshot.exists()) {
          // console.log(dataSnapshot.val())
          this.setState({
            data: dataSnapshot.val(),
            id: this.props.match.params.id,
            loading: false,
            checked: dataSnapshot.val().checked,
            date: moment(dataSnapshot.val().starttime).format('YYYY-MM-DD'),
            away: !dataSnapshot.val().away ? false : dataSnapshot.val().away,
            comment: dataSnapshot.val().comment
              ? dataSnapshot.val().comment
              : '',
          })
        }
      })
  }

  render() {
    if (this.state.loading) {
      return (
        <>
          <Container>
            <Row>
              <Col className='ml-auto mr-auto' lg='4' md='8'>
                <Card className='card-login'>
                  <CardHeader>
                    <Spinner size='sm' color='secondary' /> Loading...
                  </CardHeader>
                </Card>
              </Col>
            </Row>
          </Container>
          <div
            className='full-page-background'
            style={{
              backgroundImage: `url(${require('../assets/img/bg/chris-karidisz.jpg')})`,
            }}
          />
        </>
      )
    } else {
      return (
        <>
          <Container>
            <Row>
              <Col className='ml-auto mr-auto' lg='8' md='8'>
                <Card className='card-login'>
                  <CardHeader>Tiedot</CardHeader>
                  <CardBody>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label for='title'>Otsikko</Label>
                          <Input
                            type='text'
                            name='title'
                            id='title'
                            placeholder='Otsikko'
                            disabled={true}
                            value={this.state.data.task}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label for='description'>Kuvaus</Label>
                          <Input
                            type='textarea'
                            name='description'
                            id='description'
                            disabled={true}
                            value={this.state.data.message}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm='12'>
                        <TextField
                          id='date'
                          label='Päivä'
                          type='date'
                          disabled={true}
                          onChange={this.setStartDate}
                          defaultValue={this.state.date}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        <br />
                        <br />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm='6'>
                        <TextField
                          id='starttime'
                          label='Alkuaika'
                          type='time'
                          disabled={true}
                          onChange={this.setStarttime}
                          defaultValue={moment(this.state.data.start).format(
                            'HH:mm'
                          )}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            step: 600, // 5 min
                          }}
                        />
                      </Col>
                      <Col sm='6'>
                        <TextField
                          id='endtime'
                          label='Loppuaika'
                          type='time'
                          disabled={true}
                          onChange={this.setEndtime}
                          defaultValue={moment(this.state.data.end).format(
                            'HH:mm'
                          )}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            step: 600, // 5 min
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col className='border-top pb-2 mt-4 pt-2' sm='12'>
                        <FormGroup check>
                          <Label check>
                            <Input
                              type='checkbox'
                              name='checked'
                              id='checked'
                              onChange={this.setChecked}
                              checked={this.state.checked}
                            />
                            Tehty
                            <span className='form-check-sign'>
                              <span className='check'></span>
                            </span>
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className='pb-2 mt-2 pt-2' sm='12'>
                        <FormGroup check>
                          <Label check>
                            <Input
                              type='checkbox'
                              name='away'
                              id='away'
                              onChange={this.setAway}
                              checked={this.state.away}
                            />{' '}
                            Poissa
                            <span className='form-check-sign'>
                              <span className='check'></span>
                            </span>
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Collapse isOpen={this.state.away}>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label for='comment'>Kommentti</Label>
                            <Input
                              type='textarea'
                              name='comment'
                              id='comment'
                              onChange={this.setComment}
                              value={this.state.comment}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Collapse>

                    <Row className='pt-5'>
                      <Col className='ml-auto mr-auto' sm='8'>
                        <Button
                          color='primary'
                          onClick={this.onSave}
                          disabled={!this.state.updated}
                        >
                          {' '}
                          Tallenna
                        </Button>
                      </Col>
                      <Col className='ml-auto mr-auto' sm='4'>
                        <Button
                          color='secondary'
                          onClick={() => {
                            document.location.href = '/user/main'
                          }}
                        >
                          Peruuta
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      )
    }
  }
}

export default withFirebase(Details)
