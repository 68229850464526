import React from 'react'
import { Row, Col, Button } from 'reactstrap'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'

const WEEKDAYS = { Ma: 1, Ti: 2, Ke: 3, To: 4, Pe: 5, La: 6, Su: 7 }

const sortByDay = (a, b) => {
  return WEEKDAYS[a] - WEEKDAYS[b]
}

const byOneDimension = (data, dimension) => {
  return data.reduce((acc, cur) => {
    const value = cur[dimension]
    if (!acc[value]) acc[value] = { cost: 0, occupancy: 0, windowLength: 0 }
    acc[value].cost += parseFloat(cur.cost)
    acc[value].occupancy += parseFloat(cur.occupancy)
    acc[value].windowLength += cur.windowLength
    return acc
  }, {})
}

const byTwoDimensions = (data, dimensions) => {
  return data.reduce((acc, cur) => {
    const value1 = cur[dimensions[0]]
    const value2 = cur[dimensions[1]]
    if (!acc[value1]) acc[value1] = {}
    if (!acc[value1][value2]) acc[value1][value2] = { cost: 0, occupancy: 0, windowLength: 0 }
    acc[value1][value2].cost += parseFloat(cur.cost)
    acc[value1][value2].occupancy += parseFloat(cur.occupancy)
    acc[value1][value2].windowLength += cur.windowLength
    return acc
  }, {})
}

var tableToExcel = (function() {
  var uri = 'data:application/vnd.ms-excel;base64,'
    , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>'
    , base64 = function(s) { return window.btoa(unescape(encodeURIComponent(s))) }
    , format = function(s, c) { return s.replace(/{(\w+)}/g, function(m, p) { return c[p]; }) }
  return function(table, name) {
    if (!table.nodeType) table = document.getElementsByClassName(table)[0]
    var ctx = {worksheet: name || 'Worksheet', table: table.innerHTML}
    window.location.href = uri + base64(format(template, ctx))
  }
})()

class BusySimple extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tableRows: null,
      aggregatename: null,
    }
  }

  componentWillMount() {
    this.setState({
      report: this.props.report,
      data: [],
      // tableRows: [],
      aggregatename: null,
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.rows !== this.props.rows) {
      this.setState({ data: this.props.rows, tableRows: [], aggregatename: null })
    }
  }

  byOne = (value, head, name) => {
    console.log('byOne', value, head, name)
    console.log('data', this.state.data)
    const aggregate = byOneDimension(this.state.data, value)
    let keys = Object.keys(aggregate).sort()
    if (value === 'day') keys = keys.sort(sortByDay)
    const tableRows = keys.map((a, i) => {
      const { cost, occupancy, windowLength } = aggregate[a]
      const percent = ((occupancy / windowLength) * 100).toFixed(1)
      const avrPrice = (cost / occupancy).toFixed(2)
      return (
        <TableRow>
          <TableCell key={'ind' - i}>{a}</TableCell>
          <TableCell key={'aggcost' + i}>{cost.toFixed(2)} €</TableCell>
          <TableCell key={'agghours' + i}>
            {occupancy} tuntia, max.{windowLength}
          </TableCell>
          <TableCell key={'aggpercent' + i}>{percent} %</TableCell>
          <TableCell key={'aggavrpr' + i}>{avrPrice} €</TableCell>
        </TableRow>
      )
    })
    this.setState({
      head: [head, 'Summa €', 'Varausten summa', 'Varausaste %', 'Keskihinta/h'],
      // aggregate,
      aggregatename: name,
      tableRows,
    })
  }

  byTwo = (dimensions, name) => {
    const aggregate = byTwoDimensions(this.state.data, dimensions.values)
    if (aggregate) {
      let keys = Object.keys(aggregate).sort()
      if (dimensions.values[0] === 'day') keys = keys.sort(sortByDay)
      const tableRows = keys.map((dayIndex, di) => {
        const categories = Object.keys(aggregate[dayIndex]).sort()
        const rows = categories.map((category, i) => {
          const { cost, occupancy, windowLength } = aggregate[dayIndex][category]
          const percent = ((occupancy / windowLength) * 100).toFixed(1)
          const avrPrice = (cost / occupancy).toFixed(2)
          return (
            <TableRow>
              <TableCell key={di + '-ind-' + i}>{i === 0 ? dayIndex : ''}</TableCell>
              <TableCell key={di + 'aggcat-' + i}>{category}</TableCell>
              <TableCell key={di + 'aggcost-' + i}>{cost.toFixed(2)} €</TableCell>
              <TableCell key={di + 'agghours-' + i}>
                {occupancy} tuntia, max.{windowLength}
              </TableCell>
              <TableCell key={di + 'aggpercent-' + i}>{percent} %</TableCell>
              <TableCell key={di + 'aggavrpr-' + i}>{avrPrice} €</TableCell>
            </TableRow>
          )
        })
        return rows.flat()
      })

      this.setState({
        head: [dimensions.head[0], dimensions.head[1], 'Summa €', 'Varausten summa', 'Varausaste %', 'Keskihinta/h'],
        // aggregate,
        aggregatename: name,
        tableRows,
        inputchange: false,
      })
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.state.tableRows && (
          <React.Fragment>
            <Row>
              <Col>
                <Button
                  // disabled={!this.state.aggregatename}
                  size='sm'
                  onClick={() => this.byOne('dayIndex', 'Päivä', 'Day')}
                >
                  Päivittäin
                </Button>{' '}
                <Button
                  // disabled={!this.state.aggregatename}
                  size='sm'
                  onClick={() =>
                    this.byTwo({ head: ['Päivä', 'Kategoria'], values: ['dayIndex', 'category'] }, 'DayAndCategory')
                  }
                >
                  Päivittäin ja kategorioittain
                </Button>{' '}
                <Button
                  // disabled={!this.state.aggregatename}
                  size='sm'
                  onClick={() =>
                    this.byTwo({ head: ['Päivä', 'Kenttä'], values: ['dayIndex', 'court'] }, 'DayAndCourt')
                  }
                >
                  Päivittäin ja kentittäin
                </Button>{' '}
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  // disabled={!this.state.aggregatename}
                  size='sm'
                  onClick={() => this.byOne('day', 'Viikonpäivä', 'Weekday')}
                >
                  Viikonpäivittäin
                </Button>{' '}
                <Button
                  // disabled={!this.state.aggregatename}
                  size='sm'
                  onClick={() =>
                    this.byTwo(
                      { head: ['Viikonpäivä', 'Kategoria'], values: ['day', 'category'] },
                      'WeekdayAndCategory'
                    )
                  }
                >
                  Viikonpäivittäin ja kategorioittain
                </Button>{' '}
                <Button
                  // disabled={!this.state.aggregatename}
                  size='sm'
                  onClick={() =>
                    this.byTwo({ head: ['Viikonpäivä', 'Kenttä'], values: ['day', 'court'] }, 'WeekdayAndCourt')
                  }
                >
                  Viikonpäivittäin ja kentittäin
                </Button>{' '}
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  // disabled={!this.state.aggregatename}
                  size='sm'
                  onClick={() => this.byOne('category', 'Kategoria', 'byCategory')}
                >
                  Kategorioittain
                </Button>{' '}
              </Col>
            </Row>
          </React.Fragment>
        )}
        <Row>
          <Col>
            {this.state.aggregatename && (
              <Button size='sm' onClick={() => tableToExcel('MuiTable-root')}>
                Export to Excel
              </Button>
            )}{' '}
            <Table>
              <TableHead>
                <TableRow>
                  {this.state.head && this.state.head.map((h, i) => <TableCell key={i}>{h}</TableCell>)}
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.tableRows ? (
                  this.state.tableRows
                ) : (
                  <TableRow>
                    <TableCell>{this.state.tableRows ? 'Valitse aggregointi' : ''}</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}
export default BusySimple
