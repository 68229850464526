// import React from 'react'

const accountingClass = {
  businessid: { type: 'string', title: 'Y-tunnus' },
  taxbase: {
    type: 'number',
    title: 'Alv verokanta',
    enum: [0, 10, 17, 24],
    enumNames: ['0 %', '10 %', '17 %', '24 %'],
  }
}

export const _partnerClass = {
  displayName: { type: 'string', title: 'Nimi' },
  type: {
    type: 'string',
    title: 'Partnerityyppi',
    enum: ['seura', 'yritys'],
    enumNames: ['Seura', 'Yritys'],
  },
  ...accountingClass,

}

export const _partnerClassDeps = {}

export const _partnerUISchema = {}
