// const _membershipPersonClass = {
//   type: 'object',
//   properties: {
//     type: {
//       type: 'string',
//       title: 'Jäsenyys',
//       anyOf: [],
//     },
//     memberid: {
//       type: 'string',
//       title: 'Jäsentunnus',
//     },
//     validuntil: {
//       type: 'string',
//       format: 'date',
//       title: 'Voimassa',
//     },
//   },
// }

/*
{
  id: '1',
  image: 'https://www.mailat24.fi/_files/ProductMedia/Thumbnail_Small/10995_24748.jpg',
  desc: 'Vuokraa padel-maila',
  sku: 'padel-maila-vuokra',
  displayName: 'Padel-maila',
  price: { g: 450, m: { memid: 200 } },
  resources: ['padel-1', 'padel-2'],
  min: 1,
  max: 4,
  days: [
    { day: 0, s: '08:00', e: '23:00' },
    { day: 1, s: '08:00', e: '23:00' },
  ],
}
*/

export const _extraClass = {
  displayName: { type: 'string', title: 'Nimi' },
  desc: { type: 'string', title: 'Kuvaus' },
  type: { type: 'string', title: 'Tyyppi', enum: ['rentalitem', 'resource'], enumNames: ['Tuote', 'Toinen resurssi'], default: 'rentalitem' },
  // min: { type: 'number', title: 'Minimimäärä' },
  // max: { type: 'number', title: 'Maksimimäärä' },
  // sku: { type: 'string', title: 'Tuotetunniste' },
  // pricegeneral: { type: 'number', title: 'Hinta' },
  // accounting: { type: 'string', title: 'Tilinumero' },
  // vat: { type: 'number', title: 'ALV', enum: [0, 10, 14, 24], enumNames: ['0%', '10%', '14%', '24%'] },
  resources: {
    type: "array",
    title: "Resurssit joissa tarjolla",
    items: {
      type: "string",
      "enum": [],
      'enumNames': []
    },
    "uniqueItems": true
  }
}

export const _extraUISchema = {
  role: {
  'ui:placeholder': 'Role',
},
phone: {
  'ui:placeholder': '+358...',
},
password: {
  'ui:widget': 'password',
  },
  resources: {
    'ui:widget':'checkboxes'
  },
}

export const _extraClassDeps = {
  type: {
    oneOf: [
      {
        properties: {
          type: {
            enum: ['rentalitem'],
          },
          // image: { type: 'string', title: 'Kuva' },
          min: { type: 'number', title: 'Minimimäärä' },
          max: { type: 'number', title: 'Maksimimäärä' },
          sku: { type: 'string', title: 'Tuotetunniste' },
          pricegeneral: { type: 'number', title: 'Hinta' },
          accounting: { type: 'string', title: 'Tilinumero' },
          vat: { type: 'number', title: 'ALV', enum: [0, 10, 14, 24, 25.5], enumNames: ['0%', '10%', '14%', '24%','25,5%'] },
        },
      },
      {
        properties: {
          type: {
            enum: ['resource'],
          },
          resource: {
            type: "string",
            title: "Resurssi",
            enum: [],
            enumNames: []
          }
        },
      },
    ],
  },
}