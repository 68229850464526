/* eslint-disable no-unused-vars */
import { Grid, Select, FormControl, MenuItem, ListSubheader, InputLabel } from '@material-ui/core'
import React, { Component } from 'react'


const TRIGGERS = [
  { id: 1, name: 'Kun asiakas varaa vuoron', category: 'reservations', categoryname: 'Varaukset' },
  { id: 2, name: 'Kun', category: 'activity', categoryname: 'Aktiviteetit' },
  {
    id: 3,
    name: 'Woocommerce, Kun asiakas tekee tilauksen/ostaa tuotteen (webhook)',
    category: 'external',
    categoryname: 'Ulkoiset lähteet',
  },
]

const TERMS = {
  reservations: [{ id: 1, name: 'jäsenyys', valuetype: 'list' }, { id: 2, name: 'kesto', valuetype: 'number' }],
}

const FILTERS = {
  reservations: [
    { id: 1, name: 'jäsenyys', valuetype: 'list' },
    { id: 2, name: 'kesto', valuetype: 'number' },
    { id: 3, name: 'aika', valuetype: 'time' },
    { id: 4, name: 'etukoodi', valuetype: 'list' },
  ],
}

const OPERATORS = [
  { id: 1, name: 'on', value: '===', valuetype: 'number' },
  { id: 2, name: 'ei ole', value: '!==', valuetype: 'number' },
  { id: 3, name: 'on enemmän kuin', value: '>', valuetype: 'number' },
  { id: 4, name: 'on vähemmän kuin', value: '<', valuetype: 'number' },
  { id: 5, name: 'on enemmän tai yhtä paljon kuin', value: '>=', valuetype: 'number' },
  { id: 6, name: 'on vähemmän tai yhtä paljon kuin', value: '<=', valuetype: 'number' },
  { id: 7, name: 'on ennen', value: 'isBefore', valuetype: 'time' },
  { id: 8, name: 'on jälkeen', value: 'isAfter', valuetype: 'time' },
  { id: 9, name: 'on', valuetype: 'text', value: '===' },
  { id: 10, name: 'ei ole', valuetype: 'text', value: '!==' },
  { id: 11, name: 'sisältää', valuetype: 'text', value: 'includes' },
  { id: 12, name: 'ei sisällä', valuetype: 'text', value: 'excludes' },
]

const CREATE = [
  { id: 1, name: 'Luo etukoodi', category: 'reservations' },
]

const ACTIONS = [{
  id: 1,
  name: 'Lähetä sähköposti',
}, {
  id: 2,
  name: 'Lähetä tekstiviesti',
  }, {
  id: 3,
  name: 'Lähetä push-ilmoitus',
  }, {
}]

export default class AutomationEditor extends Component {
  constructor(props) {
    super(props)
    this.state = {
      trigger: null,
      term: null,
      filter: null,
      operator: null,
      triggercategory: null,
    }
  }

  triggerChanged = (e) => {
    console.log('triggerChanged', e.target.value)
    const trigger = TRIGGERS.find((t) => t.id === e.target.value)
    this.setState({ trigger: e.target.value, triggercategory: trigger.category })
  }

  render() {
    const triggerGroups = TRIGGERS.reduce((acc, curr) => {
      if (!acc[curr.categoryname]) {
        acc[curr.categoryname] = []
      }
      acc[curr.categoryname].push(curr)
      return acc
    }, {})

    return (
      <>
        <div className='content'>
          AutomationEditor
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl variant='outlined'>
                Kun
                <Select
                  style={{ minWidth: 200 }}
                  id='grouped-select'
                  value={this.state.trigger}
                  onChange={this.triggerChanged}
                >
                  {Object.keys(triggerGroups).map((key) => {
                    const items = [<ListSubheader key={key}>{key}</ListSubheader>]
                    items.push(
                      ...triggerGroups[key].map((item) => {
                        return (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        )
                      })
                    )
                    return items
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </div>
      </>
    )
  }
}
