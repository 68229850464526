import React from 'react'

import { AuthUserContext, UserProfileContext, ModulesContext } from './context'
import { withFirebase } from '../Fb'
import LogRocket from 'logrocket'

const isVenueUser = (claims) => {
  if (!claims) return false
  if (!claims.role) return false
  if (claims.role === 'ADMIN' || claims.role === 'CASHIER' || claims.role === 'PARTNER' || claims.role === 'COOPERATIVE') return true
}

const withAuthentication = (Component) => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        authUser: null,
        profile: null,
        modules: null,
      }
    }

    listenModules = () => {
      if (this.state.modules === null) {
        this.modulelistener = this.props.firebase.listenModules().onSnapshot((snapshot) => {
          this.setState({ modules: snapshot.data() })
        })
      }
    }

    async componentDidMount() {
      this.listener = this.props.firebase.auth
        .onAuthStateChanged((authUser) => {
          if (authUser) {
            authUser.getIdTokenResult()
              .then((idTokenResult) => {
                let cIds = [idTokenResult.claims.customerId]
                this.props.firebase.customerid = idTokenResult.claims.customerId
                if (isVenueUser(idTokenResult.claims)) {
                  console.log('venue user detected')
                  this.setState({
                    authUser: authUser,
                    profile: { role: idTokenResult.claims.role, customerIds: cIds },
                    modules: null,
                  })
                  this.listenModules()
                  LogRocket.identify(authUser.email)
                } else {
                  this.props.firebase.doSignOut()
                }
              })
             // if (!idTokenResult.claims.role) {
              //   this.props.firebase.doSignOut()
              // } else if (!isVenueUser(idTokenResult.claims)) { //.role === 'ADMIN' || idTokenResult.claims.role === 'CASHIER' || idTokenResult.claims.role === 'PARTNER')) {
              //   this.props.firebase.doSignOut()
              // } else {
              //   this.setState({
              //     authUser: authUser,
              //     profile: { role: idTokenResult.claims.role, customerIds: cIds },
              //   })
              //   LogRocket.identify(authUser.email)
              // }
              // })
              .catch((error) => {
                console.error(error)
              })
          } else {
            delete this.props.firebase.customerid
            this.setState({
              authUser: null,
              profile: null,
              modules: null,
            })
          }
        }
      )
    }

    componentWillUnmount() {
      this.listener()
      if (this.modulelistener) {
        this.modulelistener()
      }
    }

    render() {
      return (
        <AuthUserContext.Provider value={this.state.authUser}>
          <UserProfileContext.Provider value={this.state.profile}>
            <ModulesContext.Provider value={this.state.modules}>
              <Component
                {...this.props}
                authUser={this.state.authUser}
                profile={this.state.profile}
                modules={this.state.modules}
                />
            </ModulesContext.Provider>
          </UserProfileContext.Provider>
        </AuthUserContext.Provider>
      )
    }
  }

  return withFirebase(WithAuthentication)
}

export default withAuthentication
