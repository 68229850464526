import Login from './views/Login'
import Forget from './views/Forget'
import Details from './views/Details'
import AutomationEditor from './components/Details/AutomationEditor'

var subroutes = [
  {
    path: '/login',
    name: 'Login',
    icon: 'nc-icon nc-layout-11',
    component: Login,
    layout: '/auth',
  },
  {
    path: '/forget',
    name: 'Forget',
    icon: 'nc-icon nc-layout-11',
    component: Forget,
    layout: '/auth',
  },
  {
    path: '/det/:id',
    name: 'Details',
    icon: 'nc-icon nc-layout-11',
    component: Details,
    layout: '/user',
  },
  { 
    path: '/automations/:id',
    name: 'Automations',
    icon: 'nc-icon nc-layout-11',
    component: AutomationEditor,
    layout: '/admin',
  }
]
export default subroutes
