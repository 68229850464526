import React, { Component } from 'react';
import { withFirebase } from '../../components/Fb';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import WeekSelector from '../Inputs/WeekSelector';
import MaintenanceTaskList from './MaintenanceTaskList';


/**
 
 contract
  - customerid
  - name
  - description
  - validuntil
  - status

 servicedescription / order
  - contractid
  - name
  - description
  - orderer
  - orderdate
  - accepted
  - accepteddate
 
  taskgroup
  - name
  - description
  - taskids
 
  task
  - name
  - description
  - instructions (array of strings)
  - orderer
  - orderdate
  - accepted
  - accepteddate
  - schedule
    - daysofweek
    - weeks
      - included
      - excluded

execution
  - taskid
  - date
  - status
  - notes
  - images
  - personid
  - messages
  - reviews


 
 
  
 */

const demodata = {
  contracts: [
    {
      id: 1,
      name: 'Kenttä siivous',
      description: 'Kentän siivous ja hoito',
      jobs: [
        {
          id: 1,
          name: 'Kentän siivous',
          description: 'Kentän siivous ja hoito',
          daysofweek: [1, 2, 3, 4, 5],
          weeks: { 
            excluded: [1, 2, 3, 4],
          }
        },
        {
          id: 2,
          name: 'Kentän hoito',
          description: 'Kentän siivous ja hoito',
          daysofweek: [6, 7],
          weeks: {
            excluded: [1, 2, 3, 4],
          }
        },
      ],
    },
    {
      id: 2,
      name: 'Rakennuksen siivous',
      description: 'Rakennuksen siivous ja hoito',
      jobs: [
        {
          id: 1,
          name: 'Rakennuksen siivous',
          description: 'Rakennuksen siivous ja hoito',
          daysofweek: [1, 2, 3, 4, 5],
          weeks: {
            excluded: [1, 2, 3, 4],
          }
        },
        {
          id: 2,
          name: 'Rakennuksen hoito',
          description: 'Rakennuksen siivous ja hoito',
          daysofweek: [6, 7],
          weeks: {
            excluded: [1, 2, 3, 4],
          }
        },
      ],
    },
  ],
};



class TarmoServices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      reservations: [],
      selectedDate: moment(),
    };
  }

  onDateChange = (date) => {
    this.setState({ selectedDate: date });
  }

  render() {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} sm={10}>
          <WeekSelector onChange={this.onDateChange} />
        </Grid>
        <Grid item xs={12} sm={2}  style={{ textAlign: 'right'}}>
          <div>
          <img src="https://tarmopalvelut.fi/wp-content/themes/tarmopalvelut-theme/img/tarmo-palvelut-logo.png" alt="Tarmo Palvelut" />
        </div>
        </Grid>
        <Grid item xs={12}>
          <MaintenanceTaskList contracts={demodata.contracts} date={this.state.selectedDate} />
        </Grid>
     </Grid>
    );
  }
}

export default withFirebase(TarmoServices);