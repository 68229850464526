import React from 'react'
// import DemoAlert from 'views/components/DemoAlert'
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  Input,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  // ButtonGroup,
  Form,
  ModalFooter,
} from 'reactstrap'
import Switch from 'react-bootstrap-switch'
import Select from 'react-select'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Prompt } from 'react-router-dom'

const CONFIRMATION_TYPES = [{ value: 'reservation', label: 'Varaus' }, { value: 'move', label: 'Siirto' }, { value: 'cancellation', label: 'Peruutus' }]

const INIT_TEMPLATE = {
  venue: 'all',
  lang: 'all',
  channel: 'mail',
  memberships: [],
  enabled: true,
  default: false,
  message: {
    subject: '',
    body: '',
  },
}

const DEFAULT_CONFIRMATION_TEMPLATES = [
  {
    type: 'reservation',
    category: 'all',
    venue: 'all',
    lang: 'all',
    channel: 'mail',
    memberships: [],
    enabled: true,
    default: true,
    message: {
      subject: 'Varausvahvistus {SUBJECT_SHORT}',
      body:
        'Hei {PERSON_NAME},\r\n\r\nVahvistetun varauksen tiedot,\r\n\r\n' +
        'Aika	{RESERVATION_DATE_TIME},\r\n\r\nPaikka	{RESERVATION_VENUE},\r\n\r\nKenttä	{RESERVATION_COURT} / {RESERVATION_SPORT} \r\n\r\nHinta	{RESERVATION_PRICE},\r\n\r\nTervetuloa!',
    },
  },
  {
    type: 'move',
    category: 'all',
    venue: 'all',
    lang: 'all',
    channel: 'mail',
    memberships: [],
    enabled: true,
    default: true,
    message: {
      subject: 'Vuoron siirto {SUBJECT_SHORT}',
      body:
        'Hei {PERSON_NAME},\r\n\rSiirretyn varauksen tiedot,\r\n\r\n' +
        'Aika	{RESERVATION_DATE_TIME},\r\n\r\nPaikka	{RESERVATION_VENUE},\r\n\r\nKenttä	{RESERVATION_COURT} / {RESERVATION_SPORT} \r\n\r\nTervetuloa!',
    },
  },
  {
    type: 'cancellation',
    category: 'all',
    venue: 'all',
    lang: 'all',
    channel: 'mail',
    memberships: [],
    enabled: true,
    default: true,
    message: {
      subject: 'Vuoron peruutus {SUBJECT_SHORT}',
      body:
        'Hei {PERSON_NAME},\r\n\rPeruutetun varauksen tiedot,\r\n\r\n' +
        'Aika	{RESERVATION_DATE_TIME},\r\n\r\nPaikka	{RESERVATION_VENUE},\r\n\r\nKenttä	{RESERVATION_COURT} / {RESERVATION_SPORT}',
    },
  },
]

class ConfirmationMessageTemplates extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      confirmations: [],
      loadingTemplates: true,
      loadingCategories: true,
      saved: true,
      showMessageModal: false,
      selectedIndex: -1
    }
  }

  componentDidMount() {
    this.unsubscribe = this.props.firebase.getMessageTemplates().onSnapshot((doc) => {
      if (doc.exists) {
        this.setState({
          confirmations: doc.data().confirmations || DEFAULT_CONFIRMATION_TEMPLATES,
          loadingTemplates: false,
        })
      } else {
        this.setState({
          loadingTemplates: false,
          confirmations: DEFAULT_CONFIRMATION_TEMPLATES,
        })
      }
    })
    this.unsubscribeCats = this.props.firebase
      .getResourceCategories()
      .onSnapshot((doc) => {
        if (doc.exists) {
          this.setState({
            resourcecategories: doc.data().categories,
            loadingCategories: false,
          })
        } else {
          this.setState({
            loadingCategories: false,
          })
        }
      })
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe()
    }
    if (this.unsubscribeCats) {
      this.unsubscribeCats()
    }
  }

  onChange = (event) => {
    let index = Number.parseInt(event.props.name.split('.')[1])
    let _a = this.state.confirmations
    _a[index].enabled = event.state.value
    this.setState({ confirmations: _a, saved: false })
  }

  setCategory = (selection, action) => {
    let index = Number.parseInt(action.name.split('.')[1])
    let _a = this.state.confirmations
    let _s = selection.value
    _a[index].category = _s
    this.setState({ confirmations: _a, saved: false })
  }

  setType = (selection, action) => {
    let index = Number.parseInt(action.name.split('.')[1])
    let _a = this.state.confirmations
    // console.log(selection)
    let _s = selection.value
    _a[index].type = _s
    this.setState({ confirmations: _a, saved: false })
  }

  addResourceCategoryRow = () => {
    let j = this.state.confirmations
    j.push(JSON.parse(JSON.stringify(INIT_TEMPLATE)))
    this.setState({ confirmations: j })
  }

  onSave = (event) => {
    event.preventDefault()
    let _rows = this.state.confirmations.map((r) => {
      delete r.empty
      return r
    })
    this.props.firebase
      .addMessageTemplates({
        confirmations: _rows,
      })
      .then((dataSnapshot) => {
        this.setState({ saved: true })
      })
  }

  toggleMessage = () => {
    this.setState({ showMessageModal: !this.state.showMessageModal })
  }

  openEditMessageModal = (index) => {
    // console.log('index', index)
    // console.log('this.state.confirmations', this.state.confirmations)
    if (this.state.confirmations[index].type && (!this.state.confirmations[index]?.message?.subject || this.state.confirmations[index].message.subject === '')) {
      const message = DEFAULT_CONFIRMATION_TEMPLATES.find((x) => x.type === this.state.confirmations[index].type)
      let j = this.state.confirmations
      j[index].message = JSON.parse(JSON.stringify(message.message))
      this.setState({ confirmations: j, saved: false, showMessageModal: true, selectedIndex: index })
    } else {
      this.setState({ showMessageModal: true, selectedIndex: index })
    }
  }

  handleMessageBodyChange = (e) => {
    let j = this.state.confirmations
    if (!j[this.state.selectedIndex].message) j[this.state.selectedIndex].message = { body: '', subject: '' }
    j[this.state.selectedIndex].message.body = e.target.value
    this.setState({ confirmations: j, saved: false })
  }

  handleMessageSubjectChange = (e) => {
    let j = this.state.confirmations
    if (!j[this.state.selectedIndex].message) j[this.state.selectedIndex].message = { body: '', subject: '' }
    j[this.state.selectedIndex].message.subject = e.target.value
    this.setState({ confirmations: j, saved: false })
  }

  toggleDynamicHelp = () => {
    this.setState({ showDynamicHelp: !this.state.showDynamicHelp })
  }

  messageEditModal = () => {
    return (
      <Modal isOpen={this.state.showMessageModal} toggle={this.toggleMessage} size='lg'>
        <ModalHeader toggle={this.toggleMessage}>Viesti</ModalHeader>
        <ModalBody>
          <div>
            <Label for='messagetemplates'>Dynaamiset kentät</Label>
            {this.state.showDynamicHelp ? <KeyboardArrowUpIcon onClick={this.toggleDynamicHelp} /> : <KeyboardArrowDownIcon onClick={this.toggleDynamicHelp} />}
          </div>
          {this.state.showDynamicHelp ? (
          <div>
            <Row>
              <Col>&#123;SUBJECT_SHORT&#125;</Col>
              <Col>Vakiomuotoinen otsikko</Col>
            </Row>
            <Row>
              <Col>&#123;PERSON_NAME&#125;</Col>
              <Col>Asiakkaan etu- ja sukunimi</Col>
            </Row>
            <Row>
              <Col>&#123;RESERVATION_DATE_TIME&#125;</Col>
              <Col>Varauksen päivä ja aika</Col>
            </Row>
            <Row>
              <Col>&#123;RESERVATION_VENUE&#125;</Col>
              <Col>Paikka</Col>
            </Row>
            <Row>
              <Col>&#123;RESERVATION_COURT&#125;</Col>
              <Col>Kenttä</Col>
            </Row>
            <Row>
              <Col>&#123;RESERVATION_SPORT&#125;</Col>
              <Col>Kategoria / laji</Col>
            </Row>
            <Row>
              <Col>&#123;RESERVATION_PRICE&#125;</Col>
              <Col>Hinta</Col>
            </Row>
            <Row>
              <Col>&#123;RESERVATION_CODE&#125;</Col>
              <Col>Sisäänpääsykoodi</Col>
            </Row>
          </div>
            ): null }
          <Form>
            <FormGroup>
              <Label for='messageSubject'>Otsikko</Label>
              <Input
                id='messageSubject'
                value={
                  this.state.confirmations?.[this.state.selectedIndex]?.message.subject
                    ? this.state.confirmations[this.state.selectedIndex]?.message.subject
                    : ''
                }
                name='messageSubject'
                onChange={this.handleMessageSubjectChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for='messageBody'>Viesti</Label>
              <Input
                rows='15'
                value={
                  this.state.confirmations?.[this.state.selectedIndex]?.message.body
                    ? this.state.confirmations[this.state.selectedIndex]?.message.body
                    : ''
                }
                id='messageBody'
                name='messageBody'
                type='textarea'
                style={{ maxHeight: 400 }}
                onChange={this.handleMessageBodyChange}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color='primary' onClick={this.toggleMessage}>
            {'Valmis'}
          </Button>
        </ModalFooter>
      </Modal>
    )
  }

  messageTemplateRows = () => {
    if (this.state.confirmations) {
      const _categoryOptions = this.state.resourcecategories.map((rc) => ({
        value: rc.category.toLowerCase(),
        label: rc.category,
      }))
      _categoryOptions.push({ value: 'all', label: 'Kaikki' })
      return this.state.confirmations.map((row, index) => {
        let i = index
        return (
          <Row key={i} style={{ borderTop: '1px solid rgba(0, 0, 0, 0.10)' }}>
            <Col sm='2' md='2' xs='2'>
              <FormGroup>
                <Label for={'label.' + i}>Tyyppi</Label>
                <Select
                  className='react-select primary'
                  classNamePrefix='react-select'
                  name={'label.' + i}
                  id={'label.' + i}
                  isDisabled={this.state.confirmations[i].default}
                  value={
                    this.state.confirmations[i].type
                    ? CONFIRMATION_TYPES.find((x) => x.value === this.state.confirmations[i].type)
                      // ? { value: this.state.confirmations[i].type, label: this.state.confirmations[i].type }
                      : ''
                  }
                  defaultValue={CONFIRMATION_TYPES[0]}
                  onChange={this.setType}
                  options={CONFIRMATION_TYPES}
                />
              </FormGroup>
            </Col>
            <Col sm='2' md='2' xs='2'>
              <FormGroup>
                <Label for={'catlabel.' + i}>Tyyppi</Label>
                <Select
                  className='react-select primary'
                  classNamePrefix='react-select'
                  name={'catlabel.' + i}
                  id={'catlabel.' + i}
                  isDisabled={this.state.confirmations[i].default}
                  value={
                    this.state.confirmations[i].category
                      ? _categoryOptions.find((x) => x.value === this.state.confirmations[i].category)
                      : ''
                  }
                  defaultValue={{ value: 'all', label: 'Kaikki' }}
                  onChange={this.setCategory}
                  options={_categoryOptions}
                />
              </FormGroup>
            </Col>
            <Col md='1' sm='1' xs='1'>
              <Label for={'enabled.' + i}>Käytössä</Label>{' '}
              <div name={'enabled.' + i}>
                <Switch
                  defaultValue={this.state.confirmations[i].enabled}
                  onColor='primary'
                  offColor='primary'
                  id={'enabled.' + i}
                  name={'enabled.' + i}
                  onChange={this.onChange}
                  disabled={this.state.confirmations[i].default}
                />
              </div>
            </Col>
            <Col>
              <Button color={'success'} onClick={() => this.openEditMessageModal(i)}>
                Muokkaa
              </Button>
            </Col>
          </Row>
        )
      })
    }
  }

  render() {
    if (!this.state.loadingCategories && !this.state.loadingTemplates) {
      return (
        <>
          <div className='content'>
            <Row>
              <Col md='12'>
                <Card>
                  <CardHeader>
                    <Row>
                      <Col lg='10' md='5' sm='5'>
                        <CardTitle tag='h5'>Vahvistusviestit</CardTitle>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    {this.messageTemplateRows()}
                    <Row>
                      <Col style={{ marginTop: '20px' }}>
                        <Button
                          className='btn-round btn-icon'
                          color='primary'
                          size='sm'
                          onClick={() => {
                            this.addResourceCategoryRow()
                          }}
                        >
                          <i className='fa fa-plus' />
                        </Button>{' '}
                        Lisää vahvistusviesti
                      </Col>
                    </Row>
                    <Row>
                      <Col className='ml-auto mr-auto' sm='12'>
                        <Button disabled={this.state.saved} color='primary' onClick={this.onSave}>
                          Tallenna muutokset
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {this.messageEditModal()}
          </div>
          <Prompt when={!this.state.saved} message='Muutoksia ei ole tallennettu. Haluatko varmasti poistua?' />
        </>
      )
    } else {
      return <></>
    }
  }
}

export default ConfirmationMessageTemplates
