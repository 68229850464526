export const _maintenanceContractClass = {
  name: {
    type: 'string',
    title: 'Nimi',
  },
  description: {
    type: 'string',
    title: 'Kuvaus',
  },
  customerid: {
    type: 'string',
    title: 'Asiakas',
  },
  validuntil: {
    type: 'string',
    format: 'date',
    title: 'Voimassa',
  },
  status: {
    type: 'string',
    title: 'Status',
    enum: ['active', 'inactive'],
    enumNames: ['Aktiivinen', 'Ei aktiivinen'],
  },
}


export const _maintenanceContractClassDeps = {
}

export const _maintenanceContractUISchema = {
  status: {
    'ui:widget': 'radio',
  },
}


export const _maintenanceServicedescriptionClass = {
  name: {
    type: 'string',
    title: 'Nimi',
  },
  description: {
    type: 'string',
    title: 'Kuvaus',
  },
  taskgroups: {
    title: '',
    description: 'taskiryhmät',
    type: 'array',
    items: {
      type: 'object',
      properties: {
        name: { type: 'string', title: 'Nimi' },
        description: { type: 'string', title: 'Kuvaus' },
      },
    },
  },
}

export const _maintenanceServicedescriptionClassDeps = {
}

export const _maintenanceServicedescriptionUISchema = {
  description: {
    'ui:widget': 'editorWidget',
  },
}


export const _maintenanceTaskgroupClass = {
  name: {
    type: 'string',
    title: 'Nimi',
  },
  description: {
    type: 'string',
    title: 'Kuvaus',
  },
  taskids: {
    type: 'array',
    title: 'Tehtävät',
    items: {
      type: 'string',
      enum: [],
      enumNames: [],
    },
    uniqueItems: true,
  },
}

export const _maintenanceTaskgroupClassDeps = {
  taskids: {
    oneOf: [
      {
        properties: {
          taskids: {
            enum: [],
          },
        },
      },
    ],
  },
}

export const _maintenanceTaskgroupUISchema = {
  taskids: {
    'ui:widget': 'checkboxes',
  },
}