export const _bannerClass = {
  name: { type: 'string', title: 'Bannerin nimi' },
  clickurl: { type: 'string', title: 'Kohde osoite' },
  start: {
    type: 'string',
    format: 'date',
    title: 'Ensimmäinen päivä jossa näkyy',
  },
  end: {
    type: 'string',
    format: 'date',
    title: 'Viimeinen päivä jossa näkyy',
  },
  state: {
    type: 'string',
    title: 'Tila',
    enum: ['active', 'paused'],
    enumNames: ['Aktiivinen', 'Pysäytetty'],
  },
}

export const _bannerUISchema = {}