import React from 'react'
import { withFirebase } from '../../components/Fb'

// reactstrap components
import {
  Row,
  Col
} from 'reactstrap'
import Activities from '../../components/Config/Activities'
import Memberships from '../../components/Config/Memberships'
import RecurringSales from '../../components/Config/RecurringSales'
import BenefitcardSales from '../../components/Config/BenefitcardSales'

// core components

class ConfigCustomerViewability extends React.Component {
  render () {
    return (
      <>
        <div className='content'>
          {/* <Row>
            <Col md='12'>
              <iframe seamless title='Live menu' height={65} width={'100%'} allowfullscreen={false} src='http://localhost:3000/ramuclub/' style={{ overflow: 'hidden', border: 'none', sc}} />
            </Col>
          </Row> */}
          <Row>
            <Col md='12'>
              <Activities firebase={this.props.firebase} />
            </Col>
          </Row>
          <Row>
            <Col md='12'>
              <Memberships firebase={this.props.firebase} />
            </Col>
          </Row>
          {/* <Row>
            <Col md='12'>
              <RecurringSales firebase={this.props.firebase} />
            </Col>
          </Row> */}
          <Row>
            <Col md='12'>
              <BenefitcardSales firebase={this.props.firebase} />
            </Col>
          </Row>
        </div>
      </>
    )
  }
}

export default withFirebase(ConfigCustomerViewability)
