import React from 'react'
import { withFirebase } from '../../components/Fb'

// reactstrap components
import { Row, Col, Button, Label, Spinner } from 'reactstrap'
import FalconLoading from '../../components/Inputs/FalconLoading'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import moment from 'moment'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Input from '@material-ui/core/Input'
import ListItemText from '@material-ui/core/ListItemText'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import ReservationModal from '../../components/Inputs/ReservationModal'
import Switch from 'react-bootstrap-switch'
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid'
import { Checkbox, FormControlLabel, Typography } from '@material-ui/core'
import { getAlgoliaUsers } from '../../components/Algolia/algolia-utils'
import CustomerSelector from '../../components/Inputs/CustomerSelector'
import InvoicingDraft from '../../components/Details/InvoicingDraft'

function getTextWidth(text, font) {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  context.font = font || getComputedStyle(document.body).font;

  return context.measureText(text).width;
}

function TotalToolbarContainer(props) {
  if(!props.total) return null
  return (
    <Typography variant='body1' component='span' style={{ marginRight: 10 }}>
      Yhteensä: {toCurrency(props.total / 100)}
    </Typography>
  )
}

function CustomToolbar(props) {
  return (
    <GridToolbarContainer style={{ justifyContent: 'space-between' }}>
      {/* <div style={{ display: 'flex', flexDirection: 'row' }}> */}
        <div style={{ flex: 1 }}>
      <GridToolbarColumnsButton title={'Näytä/piilota sarakkeet'} />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport
        csvOptions={{
          fileName: 'laskutettavaa',
          delimiter: ';',
          utf8WithBom: true,
        }}
          />
        </div>
        <div style={{ justifyContent: 'flex-end', textAlign: 'end' }}>
          <TotalToolbarContainer total={props.total} />
          </div>  
        {/* </div> */}
    </GridToolbarContainer>
  )
}

const cleanRow = (row) => {
  const x = { ...row }
  delete x.cost
  delete x.unpaid
  delete x.rows
  delete x.datas
  delete x.actions
  if (!x.businessId) delete x.businessId
  return x
}

const SaldodividendDialog = ({
  isOpen,
  toggle,
  onSubmit,
  rows,
  saldoSum,
  processing,
}) => {
  // const [processing, setProcessing] = React.useState(false)
  let _rows = { ...rows }
  const userid = _rows.id
  delete _rows.actions
  if (!_rows.datas) _rows.datas = {}
  const occurrencies = Object.keys(_rows.datas)
    .map((key) => {
      return _rows.datas[key]
    })
    .filter((x) => x.unpaid > 0.1)
    .filter((x) => {
      if (x.collection) {
        return x.collection === 'reservations'
      }
      return true
    })
    .sort((a, b) => {
      if (a.occurency > b.occurency) return 1
      else if (a.occurency < b.occurency) return -1
      else return 0
    })
  let sumChecked = 0
  for (let i = 0; i < occurrencies.length; i++) {
    const o = occurrencies[i]
    const _o = { ...o }
    const unpaid = _o.unpaid * 100
    if (unpaid + sumChecked <= saldoSum) {
      _o.saldopaid = unpaid
      sumChecked += unpaid
    }
    occurrencies[i] = _o
  }

  const processPayment = (occurrencies, userid) => {
    console.log('processPayment', occurrencies, userid)
    onSubmit(
      occurrencies,
      userid,
      toggle
    )
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'md'}
      open={isOpen}
      onClose={toggle}
      aria-labelledby='max-width-dialog-title'
      // style={{ zIndex: '1000' }}
    >
      <DialogTitle id='max-width-dialog-title'>
        Kuitattavat vuorot (saldoa {saldoSum / 100} €)
      </DialogTitle>
      <DialogContent>
        {occurrencies.map((o, i) => {
          return (
            <Row key={o.id + '-' + i} className='border-bottom'>
              <Col md='6' className='pt-1'>
                {o.row.replace('default', '').replace('tali', '')}
              </Col>
              <Col md='3' className='pt-1 text-right'>
                {(Math.round(o.unpaid * 100) / 100)
                  .toFixed(2)
                  .replace('.', ',')}{' '}
                €
              </Col>
              <Col md='3' className='pt-1 text-right'>
                {o.saldopaid
                  ? (o.saldopaid / 100).toFixed(2).replace('.', ',') + ' €'
                  : ''}
              </Col>
            </Row>
          )
        })}
        <Row>
          <Col md='6' className='pt-1'></Col>
          <Col md='3' className='pt-1 text-right'>
            <b>Yhteensä</b>
          </Col>
          <Col md='3' className='pt-1 text-right'>
            <b>{(sumChecked / 100).toFixed(2).replace('.', ',')} €</b>
          </Col>
        </Row>
        {/* <pre>{JSON.stringify(occurrencies, null, 2)}</pre>  */}
      </DialogContent>
      <DialogActions>
        <Button
          disabled={processing}
          onClick={() =>
            processPayment(
              occurrencies.filter((o) => o.saldopaid),
              userid
            )
            // onSubmit(
            //   occurrencies.filter((o) => o.saldopaid),
            //   userid,
            //   toggle
            // )
          }
          color='primary'
        >
          {processing ? <Spinner size={'sm'} /> : 'Kuittaa vuorot saldosta'}
        </Button>
        <Button onClick={toggle} color='secondary'>
          Peruuta
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const PARAMS_TO_RESERVE_ORGANIZATION = [
  'name',
  'businessId',
  'address',
  'zipcode',
  'city',
  'email',
  'phone',
  'www',
  'id',
  'contacts',
  'users'
]

const cleanOrganization = (organization) => {
  const _organization = { ...organization }
  const keys = Object.keys(_organization)
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i]
    if (!PARAMS_TO_RESERVE_ORGANIZATION.includes(key)) {
      delete _organization[key]
    }
  }
  if (_organization.contacts) {
    _organization.contacts = _organization.contacts.map((c) => {
      const _c = { ...c }
      delete _c.path
      return _c
    })
  }
  return _organization
}

class InvoiceCustomerSelectDialog extends React.Component {
  // const InvoiceCustomerSelectDialog = ({ isOpen, toggle, onSubmit, processing }) => {
  constructor(props) {
    super(props)
    this.state = {
      processing: false,
      billingDate: null,
      selectedCustomer: null,
      addoriginalusertorow: false,
      billingreference: null,
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({
        processing: false,
        billingDate: null,
        selectedCustomer: null,
      })
    }
  }

  setBillingDate = (e) => {
    this.setState({ billingDate: e.target.value })
  }

  setCustomer = (value) => {
    console.log('setCustomer', value)
    if (!value) return
    if (value.businessId) {
      const selectedCustomer = cleanOrganization(value)
      this.setState({ selectedCustomer })
    } else {
      this.setState({ selectedCustomer: value })
    }
  }

  render() {
    return (
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={this.props.isOpen}
        onClose={this.props.toggle}
        aria-labelledby='max-width-dialog-title'
        style={{ zIndex: '1000' }}
      >
        <DialogTitle id='max-width-dialog-title'>Vahvista lähetys</DialogTitle>
        <DialogContent>
          <CustomerSelector onChange={this.setCustomer} />
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.addoriginalusertorow}
                onChange={(e) =>
                  this.setState({ addoriginalusertorow: e.target.checked })
                }
                name='addoriginalusertorow'
                color='primary'
              />
            }
            label='Lisää alkuperäinen käyttäjä laskuriville'
          />
          <br />
          <TextField
            id='billingreference'
            label='Viitenne'
            type='text'
            value={
              this.state.billingreference ? this.state.billingreference : ''
            }
            onChange={(e) =>
              this.setState({ billingreference: e.target.value })
            }
            InputLabelProps={{
              shrink: true,
            }}
          />
          <br />
          <TextField
            id='recurstart'
            label='Laskun päivä'
            type='date'
            value={this.state.billingDate ? this.state.billingDate : ''}
            onChange={this.setBillingDate}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={
              !this.state.billingDate ||
              this.state.processing ||
              !this.state.selectedCustomer
            }
            onClick={() =>
              this.props.onSubmit(
                'external',
                this.state.billingDate,
                this.state.selectedCustomer,
                this.state.addoriginalusertorow,
                this.state.billingreference
              )
            }
            color='primary'
          >
            {this.state.processing ? <Spinner size='sm' /> : null} Muodosta
            lasku
          </Button>
          <Button onClick={this.props.toggle} color='secondary'>
            Peruuta
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const isInRequestedDates = (occurency, startDate, endDate) => {
  return moment.utc(occurency).isBetween(startDate, endDate, 'day', '[]')
}

const isAtVenue = (selectedVenue, venue) => {
  if (selectedVenue) {
    return venue.toLowerCase() === selectedVenue.toLowerCase()
  }
  return true
}

const isSeasonBillingList = (showseasonbilling, rowbilling) => {
  if (showseasonbilling) {
    return rowbilling ? true : false
  } else {
    return rowbilling ? false : true
  }
}

const isTagFiltered = (tagFilter, row) => {
  if (tagFilter.tags.length > 0) {
    if (row.tags && row.tags.length > 0) {
      for (let i = 0; i < row.tags.length; i++) {
        const tag = row.tags[i]
        if (tagFilter.tags.includes(tag)) {
          if (tagFilter.mode === 'inc') {
            return true
          } else {
            return false
          }
        } else {
          if (tagFilter.mode === 'inc') {
            return false
          } else {
            return true
          }
        }
      }
    } else {
      if (tagFilter.mode === 'inc') {
        return false
      } else {
        return true
      }
    }
  } else {
    return true
  }
}

const isFilteredIn = (filters, row) => {
  const moved =
    row.row && row.row.indexOf('Siirretty vakiovuoro') > -1 ? true : false
  const tagsFiltered = isTagFiltered(filters.tags, row)
  if (!tagsFiltered) return false
  if (filters.recurrencies && (row.isrecurring || moved)) {
    return true
  }
  if (filters.singles && !row.isrecurring && !moved) {
    return true
  }
  return false
}

const toCurrency = (value) => {
  return (Math.round(value * 100) / 100).toFixed(2).replace('.', ',') + ' €'
}

// core components

class InvoicingContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      invoicingDialogOpen: false,
      invoicings: null,
      startDate: moment().utc(), //moment().subtract(1, 'years'),
      endDate: moment().utc(), //moment().add(2, 'years'),
      externalConfirmationOpen: false,
      saldodividendDialogOpen: false,
      externalBatchConfirmationOpen: false,
      finvoiceConfirmationOpen: false,
      emailInvoiceConfirmationOpen: false,
      onlineConfirmationOpen: false,
      venues: [],
      selectedVenue: null,
      reservationModalOpen: false,
      showseasonals: false,
      billingDate: null,
      actionWait: false,
      selections: [],
      selectionModel: [],
      tagFilter: null,
      filters: {
        recurrencies: true,
        singles: true,
        tags: { mode: 'exc', tags: [] },
      },
      selectedsaldo: { rows: [], sum: 0 },
      selectedFilterTags: [],
      total: 0,
      pageSize: 50,
    }
  }

  componentDidMount() {
    // this.updateData()
    this.listenData()
    this.getVenues()
    this.getInvoicingTags()
  }

  componentWillUnmount() {
    if (this.invoicesRef) {
      // this.unsubscribeInvoices()
      this.invoicesRef.off('value', this.unsubscribeInvoices)
    }
  }

  toggleReservationModal = () => {
    this.toggleInvoicingModal()
    this.setState({ reservationModalOpen: !this.state.reservationModalOpen })
  }

  toggleSaldodividendDialog = () => {
    console.log('toggleSaldodividendDialog')
    if (this.state.saldodividendDialogOpen) {
      this.setState({
        selectedsaldo: { rows: [], sum: 0 },
        saldodividendDialogOpen: !this.state.saldodividendDialogOpen,
      })
    } else {
      this.setState({
        saldodividendDialogOpen: !this.state.saldodividendDialogOpen,
      })
    }
  }

  onSaldodividentSelected = async (id, sum) => {
    const row = this.state.invoicings.find((r) => r.id === id)
    if (row) {
      this.setState(
        { selectedsaldo: { rows: row, sum } },
        this.toggleSaldodividendDialog
      )
    }
  }

  addSaldoPayments = async (rows, userid, toggle) => {
    this.setState({ actionWait: true })
    const response = await this.props.firebase.batchAddSaldos(userid, rows)
    console.log(response)
    if (!response.data.error) {
      this.setState({ actionWait: false }, this.filterData)
    } else {
      this.setState({ actionWait: false })
      alert(
        'ERROR: Ota yhteyttä tukeen. Error viesti' +
          JSON.stringify(response.data.message)
      )
    }
    console.log('addSaldoPayments', rows, 'userid', userid)
    toggle()
  }

  handleSelectReservation = async (id, occurency) => {
    const reservation = await this.props.firebase.getReservation(id)
    const start = moment.utc(reservation.data().resourcegroups[0].start)
    const end = moment.utc(reservation.data().resourcegroups[0].end)
    const selectedReservation = {
      id: reservation.id,
      start: moment
        .utc(occurency)
        .hour(start.hour())
        .minutes(start.minutes())
        .format(),
      end: moment
        .utc(occurency)
        .hour(end.hour())
        .minutes(end.minutes())
        .format(),
    }
    selectedReservation.data = { ...reservation.data(), id: reservation.id }
    this.setState({ selectedReservation }, this.toggleReservationModal)
  }

  showSeasonBilled = (event) => {
    this.setState({ showseasonals: event.state.value }, this.filterData)
  }

  getVenues = async () => {
    const _v = await this.props.firebase.getVenues()

    if (!_v.empty) {
      const venues = _v.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      this.setState({ venues })
    }
  }

  listenData = () => {
    if (this.invoicesRef) {
      this.invoicesRef.off('value', this.unsubscribeInvoices)
    }
    console.log('getting invoices')
    this.invoicesRef = this.props.firebase.listenInvoicingsRt()
    this.unsubscribeInvoices = this.invoicesRef.on('value', (snapshot) => {
      console.log('get snapshot')
      const data = []
      snapshot.forEach((user) => {
        data.push({ ...user.val(), id: user.key })
      })
      this.setState({ raw: data }, this.filterData)
    })
  }

  getInvoicingTags = () => {
    this.props.firebase
      .getColorCategories()
      .get()
      .then((_cats) => {
        console.log('get color categories', _cats.data())
        const invoicetags = _cats
          .data()
          .categories.filter((c) => c.enabled && c.type === 'tag' && c.billing)
        this.setState({ invoicetags })
      })
  }

  updateData = () => {
    this.props.firebase.getInvoices().then((snapshot) => {
      const data = []
      // eslint-disable-next-line
      snapshot.docs.map(async (doc) => {
        if (doc.data().state !== 'deleted') {
          data.push({ ...doc.data(), id: doc.id })
        }
      })
      this.setState({ raw: data }, this.filterData)
    })
  }

  setBillingDate = (e) => {
    this.setState({ billingDate: e.target.value })
  }

  setStart = (e) => {
    this.setState({ billingDate: null, startDate: moment.utc(e.target.value) })
  }

  setEnd = (e) => {
    this.setState({
      billingDate: null,
      endDate: moment.utc(e.target.value) /*.hour(23).minute(59).seconds(59)*/,
    })
  }

  setTagFilter = (filter) => {
    this.setState({ tagFilter: filter })
  }

  handleRowSelect = (e) => {
    const _result = this.state.invoicings.find((r) => r.id === e)
    if (_result.rows > 2000) {
      alert('Valitse lyhyempi ajanjakso. Yli 2000 riviä hidastaa selaimen.')
    } else {
      this.setState({ selectedResource: _result }, this.toggleInvoicingModal)
    }
  }

  checkSaldos = async () => {
    const ids = this.state.invoicings.map((i) => i.id)
    const response = await getAlgoliaUsers(ids, [
      this.props.firebase.customerid + '.saldos',
    ])
    console.log(response.results)
    const venue = this.state.selectedVenue || 'default'
    const saldos = response.results
      .filter((x) => x)
      .map((r) => {
        const _r = {}
        _r.id = r.objectID
        if (r) {
          if (
            r[this.props.firebase.customerid] &&
            r[this.props.firebase.customerid].saldos &&
            r[this.props.firebase.customerid].saldos[venue] &&
            r[this.props.firebase.customerid].saldos[venue].cash
          ) {
            _r.saldo = r[this.props.firebase.customerid].saldos[venue].cash
          } else {
            _r.saldo = 0
          }
        }
        return _r
      })
    console.log(saldos)
    const invoicings = this.state.invoicings.map((i) => {
      const _i = { ...i }
      const saldo = saldos.find((s) => s.id === i.id)
      if (saldo) {
        _i.saldo = saldo.saldo
      } else {
        _i.saldo = 0
      }
      return _i
    })
    this.setState({ saldos, invoicings })
  }

  setVenue = (e) => {
    const selectedVenue = e.target.value
    this.setState({ selectedVenue })
  }

  filterData = async () => {
    const data = []
    let alltags = []
    for (let i = 0; i < this.state.raw.length; i++) {
      if (!this.state.raw[i]?.rows) continue
      const keys = Object.keys(this.state.raw[i].rows)
      const _alltags = keys
        .map((key) => {
          let tags = []
          if (
            this.state.raw[i].rows[key].tags &&
            this.state.raw[i].rows[key].tags.length > 0
          ) {
            for (let t = 0; t < this.state.raw[i].rows[key].tags.length; t++) {
              const tag = this.state.raw[i].rows[key].tags[t]
              if (!tags.includes(tag)) tags.push(tag)
            }
          }
          return tags
        })
        .flat()
      alltags.push(..._alltags)

      const sums = keys.reduce(
        (acc, cur) => {
          if (
            isInRequestedDates(
              this.state.raw[i].rows[cur].occurency,
              this.state.startDate,
              this.state.endDate
            ) &&
            this.state.raw[i].rows[cur].unpaid &&
            isAtVenue(
              this.state.selectedVenue,
              this.state.raw[i].rows[cur].venue.toLowerCase()
            ) &&
            isSeasonBillingList(
              this.state.showseasonals,
              this.state.raw[i].rows[cur].seasonbilling
            ) &&
            isFilteredIn(this.state.filters, this.state.raw[i].rows[cur])
          ) {
            acc.cost += this.state.raw[i].rows[cur].cost
            acc.unpaid += this.state.raw[i].rows[cur].unpaid
            acc.rows++
            acc.keys.push(cur)
            if (
              this.state.raw[i].rows[cur].tags &&
              this.state.raw[i].rows[cur].tags.length > 0
            ) {
              for (
                let t = 0;
                t < this.state.raw[i].rows[cur].tags.length;
                t++
              ) {
                const tag = this.state.raw[i].rows[cur].tags[t]
                if (!alltags.includes(tag)) alltags.push(tag)
              }
            }
          }
          return acc
        },
        { cost: 0, unpaid: 0, rows: 0, keys: [] }
      )
      sums.cost = Math.round(sums.cost * 100) / 100
      sums.unpaid = Math.round(sums.unpaid * 100) / 100
      if (sums.keys.length > 0) {
        const filteredData = {}
        for (let h = 0; h < sums.keys.length; h++) {
          filteredData[sums.keys[h]] = this.state.raw[i].rows[sums.keys[h]]
        }
        let contactDisplayName = ''
        let businessId = null
        if (this.state.raw[i].contact) {
          if (this.state.raw[i].contact.businessId) {
            contactDisplayName = this.state.raw[i].contact.name
            businessId = this.state.raw[i].contact.businessId
          } else {
            contactDisplayName = [
              this.state.raw[i].contact.firstname,
              this.state.raw[i].contact.lastname,
            ].join(' ')
          }
        } else if (this.state.raw[i].user) {
          contactDisplayName =
            'user:' +
            [
              this.state.raw[i].user.firstname,
              this.state.raw[i].user.lastname,
            ].join(' ')
        }
        data.push({
          ...sums,
          id: this.state.raw[i].id,
          user: contactDisplayName,
          businessId,
          datas: filteredData,
          actions: this.getActions(this.state.raw[i].id),
        })
      }
    }
    const stateToUpdate = {
      loading: false,
      invoicings: data,
      alltags: alltags.filter(
        (value, index, array) => array.indexOf(value) === index
      ),
    }
    if (this.state.selectedResource) {
      const _selectedResource = { ...this.state.selectedResource }
      const selected = data.find((d) => d.id === _selectedResource.id)
      if (selected) {
        stateToUpdate.selectedResource = selected
      }
    }
    this.setState(stateToUpdate)
  }

  // deleteContract = async (id) => {
  //   await this.props.firebase.deleteContract(id)
  //   this.setState({ loading: true }, this.updateData)
  // }

  getDownloadUrl = (row) => {
    if (row.file) {
      return (
        <>
          <a href={row.file} target='_blank' rel='noopener noreferrer'>
            Lataa
          </a>
        </>
      )
    } else {
      return <></>
    }
  }

  toggleSelectRow = (id) => {
    const index = this.state.selections.findIndex((cur) => cur === id)
    const newSelections = this.state.selections
    if (index === -1) {
      newSelections.push(id)
    } else {
      newSelections.splice(index, 1)
    }
    this.setState({ selections: newSelections })
  }

  getActions = (invoicingid) => {
    return (
      <>
        <div className='actions-right'>
          <Button
            color='success'
            size='sm'
            onClick={(el) => {
              this.handleRowSelect(invoicingid)
            }}
          >
            Näytä rivit
          </Button>

          {/* <Button
            color='success'
            size='sm'
            onClick={(el) => {
              this.setState({ selectedInvoicingId: invoicingid }, this.toggleOnlineDialog)
            }}
            disabled={!this.state.selectedVenue || this.state.selections.length > 1}
          >
            Lähetä online maksu
          </Button> */}
          <Button
            color='info'
            size='sm'
            onClick={(el) => {
              this.setState(
                { selectedInvoicingId: invoicingid },
                this.toggleExternalDialog
              )
            }}
            disabled={
              (this.state.venues &&
                this.state.venues.length > 1 &&
                !this.state.selectedVenue) ||
              this.state.selections.length > 1
            }
          >
            Muodosta lasku
          </Button>
          {/* <Button
            color='info'
            size='sm'
            onClick={(el) => {
              this.setState({ selectedInvoicingId: invoicingid }, this.toggleFinvoiceDialog)
            }}
            disabled={!this.state.selectedVenue || this.state.selections.length > 1}
          >
            Lataa Finvoice
          </Button> */}
          {/* <Button
            color='info'
            size='sm'
            onClick={(el) => {
              this.setState({ selectedInvoicingId: invoicingid }, this.toggleEmailInvoiceDialog)
            }}
          >
            Lähetä email
          </Button> */}
        </div>
      </>
    )
  }

  toggleSelectAll = () => {
    if (this.state.selectionModel.length === this.state.invoicings.length) {
      this.setState({ selectionModel: [] })
    } else {
      const se = this.state.invoicings.map((i) => i.id)
      this.setState({ selectionModel: se })
    }
    if (this.state.selections.length === this.state.invoicings.length) {
      this.setState({ selections: [] }, this.filterData)
    } else {
      const se = this.state.invoicings.map((i) => i.id)
      this.setState({ selections: se }, this.filterData)
    }
  }

  toggleInvoicingModal = () => {
    this.setState({
      invoicingDialogOpen: !this.state.invoicingDialogOpen,
    })
  }

  toggleExternalBatchDialog = () => {
    this.setState({
      externalBatchConfirmationOpen: !this.state.externalBatchConfirmationOpen,
    })
  }

  toggleExternalCustomerSelectDialog = () => {
    this.setState({
      externalCustomerSelectConfirmationOpen:
        !this.state.externalCustomerSelectConfirmationOpen,
    })
  }

  toggleExternalDialog = () => {
    this.setState({
      externalConfirmationOpen: !this.state.externalConfirmationOpen,
    })
  }

  toggleEmailInvoiceDialog = () => {
    this.setState({
      emailInvoiceConfirmationOpen: !this.state.emailInvoiceConfirmationOpen,
    })
  }

  toggleFinvoiceDialog = () => {
    this.setState({
      finvoiceConfirmationOpen: !this.state.finvoiceConfirmationOpen,
    })
  }

  toggleOnlineDialog = () => {
    this.setState({
      onlineConfirmationOpen: !this.state.onlineConfirmationOpen,
    })
  }

  closeRowDetails = () => {
    // clear State
    this.setState(
      { selectedResource: null, tagFilter: null },
      this.toggleInvoicingModal
    )
  }

  renderInvoiceExtras = () => {
    return (
      <>
        {' '}
        <TextField
          id='recurstart'
          label='Laskun päivä'
          type='date'
          value={this.state.billingDate ? this.state.billingDate : ''}
          onChange={this.setBillingDate}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </>
    )
  }

  // renderRows = () => {
  //   let unpaidsum = 0
  //   let costsum = 0
  //   const showVenue = this.state.venues && this.state.venues.length > 1
  //   const tags = []
  //   const sorted = Object.keys(this.state.selectedResource.datas)
  //     .map((key) => {
  //       const r = this.state.selectedResource.datas[key]
  //       if (r.tags && r.tags.length > 0) {
  //         const tag = r.tags[0]
  //         if (this.state.invoicetags) {
  //           const isInvoiceTag = this.state.invoicetags.find(
  //             (t) => t.category.toLowerCase() === tag.toLowerCase()
  //           )
  //           if (!tags.includes(tag) && isInvoiceTag) {
  //             tags.push(tag)
  //           }
  //         }
  //       }
  //       return r
  //     })
  //     .filter((x) => x)
  //     .sort((a, b) => {
  //       if (a.occurency > b.occurency) return 1
  //       else if (a.occurency < b.occurency) return -1
  //       else return 0
  //     })

  //   let buttons = null
  //   if (tags.length > 0) {
  //     buttons = (
  //       <ButtonGroup size='sm'>
  //         {tags.map((key) => {
  //           return (
  //             <Button
  //               color={this.state.tagFilter === key ? 'primary' : 'action'}
  //               key={key}
  //               onClick={() => this.setTagFilter(key)}
  //             >
  //               {key}
  //             </Button>
  //           )
  //         })}
  //         <Button
  //           color={this.state.tagFilter === 'all' ? 'primary' : 'action'}
  //           key={'all'}
  //           onClick={() => this.setTagFilter('all')}
  //         >
  //           Kaikki
  //         </Button>
  //       </ButtonGroup>
  //     )
  //   }
  //   const rows = sorted
  //     .map((_r) => {
  //       if (this.state.tagFilter) {
  //         if (_r.tags && _r.tags.length > 0) {
  //           const tag = _r.tags[0]
  //           if (this.state.tagFilter === 'all') {
  //             // all ok
  //           } else {
  //             if (tag !== this.state.tagFilter) {
  //               return null
  //             }
  //           }
  //         } else {
  //           if (this.state.tagFilter !== 'all') {
  //             return null
  //           }
  //         }
  //       }
  //       costsum += _r.cost
  //       unpaidsum += _r.unpaid
  //       return (
  //         <Row key={Math.random()} className='border-bottom'>
  //           <Col md='3' className='pt-1'>
  //             {_r.row.replace('default', '').replace('tali', '')}
  //           </Col>
  //           <Col md='1' className='pt-1 text-right'>
  //             {(Math.round(_r.unpaid * 100) / 100).toFixed(2).replace('.', ',')}{' '}
  //             €
  //           </Col>
  //           <Col md='1' className='pt-1 text-right'>
  //             {(Math.round(_r.cost * 100) / 100).toFixed(2).replace('.', ',')} €
  //           </Col>
  //           <Col md='1' className='pt-1'>
  //             {_r.vat} %
  //           </Col>
  //           {showVenue ? (
  //             <Col md='1' className='pt-1'>
  //               {_r.venue === 'tali' || _r.venue === 'default' ? '' : _r.venue}
  //             </Col>
  //           ) : null}
  //           <Col md='1' className='pt-1'>
  //             {_r.seasonbilling ? 'Kyllä' : ''}
  //           </Col>
  //           <Col md='2' className='pt-1'>
  //             {JSON.stringify(_r.usernotes)}
  //           </Col>
  //           <Col md='1' className='pt-1'>
  //             {_r.tags && _r.tags.length > 0 ? _r.tags[0] : ''}
  //           </Col>
  //           <Col md='1'>
  //             {!_r.collection ? (
  //               <Button
  //                 className='mt-0 mb-0'
  //                 size='sm'
  //                 color='link'
  //                 onClick={() =>
  //                   this.handleSelectReservation(_r.id, _r.occurency)
  //                 }
  //               >
  //                 Avaa varaus
  //               </Button>
  //             ) : null}
  //           </Col>
  //         </Row>
  //       )
  //     })
  //     .filter((x) => x)

  //   return (
  //     <>
  //       {buttons}
  //       <Row>
  //         <Col md='3'>
  //           <b>Rivi</b>
  //         </Col>
  //         <Col md='1' className='text-right'>
  //           <b>Maksamatta</b>
  //         </Col>
  //         <Col md='1' className='text-right'>
  //           <b>Summa</b>
  //         </Col>
  //         <Col md='1'>
  //           <b>VAT</b>
  //         </Col>
  //         {showVenue ? (
  //           <Col md='1'>
  //             <b>Venue</b>
  //           </Col>
  //         ) : null}
  //         <Col md='1'>
  //           <b>Kausilaskutus</b>
  //         </Col>
  //         <Col md='2'>
  //           <b>Notes</b>
  //         </Col>
  //         <Col md='1'></Col>
  //       </Row>
  //       {rows}
  //       <Row>
  //         <Col md='3'>{''}</Col>
  //         <Col md='1' className='text-right'>
  //           <b>
  //             {(Math.round(unpaidsum * 100) / 100).toFixed(2).replace('.', ',')}{' '}
  //             €
  //           </b>
  //         </Col>
  //         <Col md='1' className='text-right'>
  //           {(Math.round(costsum * 100) / 100).toFixed(2).replace('.', ',')} €
  //         </Col>
  //         <Col md='1'>{''}</Col>
  //         <Col md='1'>{''}</Col>
  //         <Col md='2'>{''}</Col>
  //         <Col md='1'>{''}</Col>
  //       </Row>
  //     </>
  //   )
  // }

  confirmCombinedInvoiceAction = async (
    action,
    billingDate,
    payer,
    addoriginalusertorow,
    billingreference
  ) => {
    this.setState({ actionWait: true })
    const rows = this.state.invoicings
      .filter((r) => this.state.selectionModel.includes(r.id))
      .map((y) => {
        const x = JSON.parse(JSON.stringify(y))
        delete x.cost
        delete x.unpaid
        delete x.rows
        delete x.datas
        delete x.actions
        if (!x.businessId) delete x.businessId
        return x
      })
    if (payer) {
    }
    console.log('confirmCombinedInvoiceAction', rows, payer, billingDate)
    const response = await this.props.firebase.invoiceCombinedAction(
      rows,
      payer,
      this.state.selectedVenue || 'default',
      billingDate,
      addoriginalusertorow,
      billingreference
    )
    if (response.data.status === 'ok') {
      this.setState({ actionWait: false, selections: [] }, this.filterData)
    } else {
      this.setState({ actionWait: false })
      alert(
        'ERROR: Ota yhteyttä tukeen. Error viesti' +
          JSON.stringify(response.data)
      )
    }
    this.toggleExternalCustomerSelectDialog()
  }

  confirmedBatchAction = async (action, markAsInvoiced, toggle) => {
    this.setState({ actionWait: true })
    const rows = this.state.invoicings
      .filter((r) => this.state.selectionModel.includes(r.id))
      .map((y) => {
        return cleanRow(y)
      })
    // const response = await this.props.firebase.invoiceBatchAction(
    const response = await this.props.firebase.invoiceCreateAction(
      rows,
      action,
      markAsInvoiced,
      this.state.selectedVenue || 'default',
      this.state.billingDate
    )
    console.log(response)
    if (response.data.status === 'ok') {
      this.setState({ actionWait: false, selections: [] }, this.filterData)
    } else {
      this.setState({ actionWait: false })
      alert(
        'ERROR: Ota yhteyttä tukeen. Error viesti' +
          JSON.stringify(response.data)
      )
    }
    toggle()
  }

  confirmedAction = async (action, markAsInvoiced, toggle) => {
    this.setState({ actionWait: true })
    const row = this.state.invoicings.find(
      (r) => r.id === this.state.selectedInvoicingId
    )
    await this.props.firebase.invoiceAction(
      this.state.selectedInvoicingId,
      row.keys,
      action,
      markAsInvoiced,
      this.state.selectedVenue || 'default',
      this.state.billingDate
    )
    // console.log(response)
    this.setState({ actionWait: false })
    toggle()
  }

  confirmedCreateAction = async (action, toggle) => {
    this.setState({ actionWait: true })
    const row = this.state.invoicings.find(
      (r) => r.id === this.state.selectedInvoicingId
    )
    const x = cleanRow(row)

    await this.props.firebase.invoiceCreateAction(
      [x],
      action,
      true,
      this.state.selectedVenue || 'default',
      this.state.billingDate
    )
    // console.log(response)
    this.setState({ actionWait: false })
    toggle()
  }

  getColumns = (nameWidth) => {
    console.log('getColumns', nameWidth)
    const cols = 5
    return [
      // {
      //   Header: 'Select',
      //   field: '',
      //   width: 50,
      //   filterable: false,
      //   Cell: ({ original }) => {
      //     return (
      //       <input
      //         type='checkbox'
      //         className='checkbox'
      //         checked={this.state.selections.includes(original.id)}
      //         onChange={() => this.toggleSelectRow(original.id)}
      //         disabled={this.state.venues && this.state.venues.length > 1 && !this.state.selectedVenue}
      //       />
      //     )
      //   },
      // },
      {
        headerName: 'Asiakas',
        field: 'user',
        width: nameWidth,
        // flex: 1 / cols,
      },
      {
        headerName: 'Summa',
        field: 'cost',
        type: 'number',
        filterable: false,
        maxWidth: 200,
        minWidth: 100,
        width: 150,
        // flex: 1 / cols,
        valueFormatter: (params) => {
          return toCurrency(params.value)
        },

        // Cell: (props) => <div style={{ textAlign: 'right' }}>{toCurrency(props.value)}</div>,
      },
      {
        headerName: 'Maksamatta',
        field: 'unpaid',
        type: 'number',
        filterable: false,
        maxWidth: 200,
        minWidth: 100,
        width: 150,
        // flex: 1 / cols,
        valueFormatter: (params) => {
          return toCurrency(params.value)
        },
        // Cell: (props) => <div style={{ textAlign: 'right' }}>{toCurrency(props.value)}</div>,
      },
      {
        headerName: 'Rivejä',
        field: 'rows',
        filterable: false,
        maxWidth: 200,
        minWidth: 80,
        width: 80,
        sortable: true,
        // flex: 1 / cols,
        // renderCell: (params) => {
        //   return (
        //     <div className='actions-right'>
        //       {params.value}
        //       {' kpl '}
        //       {/* <Button
        //         color='success'
        //         size='sm'
        //         onClick={(el) => {
        //           this.handleRowSelect(params.row.id)
        //         }}
        //       >
        //         Näytä rivit
        //       </Button> */}
        //     </div>
        //   )
        // },
      },
      {
        headerName: 'Toiminnot',
        field: 'action',
        sortable: false,
        filterable: false,
        maxWidth: 550,
        minWidth: 550,
        width: 550,
        flex: 1 / cols,
        renderCell: (params) => {
          let saldoButton = null
          if (params.row.saldo) {
            saldoButton = (
              <Button
                color='success'
                size='sm'
                onClick={(el) => {
                  this.onSaldodividentSelected(params.row.id, params.row.saldo)
                }}
                disabled={
                  this.state.venues &&
                  this.state.venues.length > 1 &&
                  !this.state.selectedVenue
                }
              >
                Kuittaa saldosta ({params.row.saldo / 100} €)
              </Button>
            )
          }

          if (
            this.state.selectionModel &&
            this.state.selectionModel.length > 1
          ) {
            return <div className='actions-right'></div>
          } else {
            return (
              <div className='actions-right'>
                <Button
                color='success'
                size='sm'
                onClick={(el) => {
                  this.handleRowSelect(params.row.id)
                }}
              >
                Näytä rivit
                </Button>
                {' '}
                <Button
                  color='info'
                  size='sm'
                  onClick={(el) => {
                    this.setState(
                      { selectedInvoicingId: params.row.id },
                      this.toggleExternalDialog
                    )
                  }}
                  disabled={
                    (this.state.venues &&
                      this.state.venues.length > 1 &&
                      !this.state.selectedVenue) ||
                    this.state.selections.length > 1
                  }
                >
                  Muodosta lasku
                </Button>
                {saldoButton}
              </div>
            )
          }
        },
      },
    ]
  }

  handleFilterChange = (e) => {
    console.log(e.target.name, e.target.checked)
    const filters = this.state.filters
    filters[e.target.name] = e.target.checked
    this.setState({ filters }, this.filterData)
  }

  handleTagFilterChange = (e) => {
    const filters = this.state.filters
    filters.tags.tags = e.target.value
    this.setState({ filters }, this.filterData)
  }

  handleTagfilterModeChange = (e) => {
    const filters = this.state.filters
    filters.tags.mode = e.target.value
    this.setState({ filters }, this.filterData)
  }

  countTotal = (state) => {
    console.log('countTotal', state)
    let total = 0
    if (state.filter.visibleRowsLookup) {
      const lookup = state.filter.visibleRowsLookup
      if (lookup && Object.keys(lookup).length > 0) {
        const visibleItems = Object.keys(lookup).filter((key) => lookup[key] === true)
        const res = this.state.invoicings.filter((item) => visibleItems.includes(item.id))
        total = res
          .map((item) => item.unpaid * 100)
          .reduce((a, b) => a + b, 0)
      } else {
        return
      }
    } else {
      total = this.state.invoicings.map((item) => item.unpaid * 100).reduce((a, b) => a + b, 0)
    }
    if (this.state.totalSum === total) return
    this.setState({ totalSum: total })
  }

  render() {
    if (this.state.loading) {
      return <FalconLoading />
    } else {
      const longestUsername = this.state.invoicings.reduce((acc, cur) => {
        if (cur.user.length > acc.length) {
          acc.name = cur.user
          acc.length = cur.user.length

        } 
        return acc
      }, { name: '', length: 0 })
      const nameWidth = Math.min(Math.round(getTextWidth(longestUsername.name, '12px Roboto')) + 130, 400)
      return (
        <>
          <div className='content'>
            {/* <Row>
            </Row> */}
            <Row style={{ marginTop: 80 }}>
              <Col>
                <TextField
                  id='recurstart'
                  label='Alkupäivä'
                  type='date'
                  // defaultValue={this.state.startDate ? this.state.startDate.format('YYYY-MM-DD') : ''}
                  value={
                    this.state.startDate
                      ? this.state.startDate.format('YYYY-MM-DD')
                      : moment.utc().format('YYYY-MM-DD')
                  }
                  onChange={this.setStart}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Col>
              <Col>
                <TextField
                  id='recurstart'
                  label='Loppupäivä'
                  type='date'
                  value={
                    this.state.endDate
                      ? this.state.endDate.format('YYYY-MM-DD')
                      : moment.utc().format('YYYY-MM-DD')
                  }
                  onChange={this.setEnd}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Col>
              {this.state.venues && this.state.venues.length > 1 ? (
                <Col>
                  <FormControl style={{ width: '100%' }}>
                    <InputLabel id='venue'>Paikka</InputLabel>
                    <Select
                      fullWidth
                      labelId='venue-label'
                      id='venue'
                      value={this.state.selectedVenue || ''}
                      onChange={this.setVenue}
                    >
                      {this.state.venues.map((v, i) => {
                        return (
                          <MenuItem
                            key={v.name.toLowerCase()}
                            value={v.name.toLowerCase()}
                          >
                            {v.name}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </Col>
              ) : null}
              <Col>
                <Button
                  onClick={() =>
                    this.setState({ selections: [] }, this.filterData)
                  }
                >
                  Päivitä
                </Button>
              </Col>
              <Col>
                <Button
                  onClick={() =>
                    this.setState(
                      {
                        startDate: moment.utc().startOf('day'),
                        endDate: moment.utc().endOf('day'),
                        selections: [],
                      },
                      this.filterData
                    )
                  }
                >
                  Tämä päivä
                </Button>
              </Col>
              <Col>
                <Button
                  onClick={() =>
                    this.setState(
                      {
                        startDate: moment
                          .utc()
                          .subtract(1, 'day')
                          .startOf('day'),
                        endDate: moment.utc().subtract(1, 'day').endOf('day'),
                        selections: [],
                      },
                      this.filterData
                    )
                  }
                >
                  Eilinen
                </Button>
              </Col>
              <Col>
                <Button
                  onClick={() =>
                    this.setState(
                      {
                        startDate: moment.utc().startOf('month'),
                        endDate: moment.utc().endOf('month'),
                        selections: [],
                      },
                      this.filterData
                    )
                  }
                >
                  Tämä kuukausi
                </Button>
              </Col>
              <Col>
                <Button
                  onClick={() =>
                    this.setState(
                      {
                        startDate: moment
                          .utc()
                          .subtract(1, 'month')
                          .startOf('month'),
                        endDate: moment
                          .utc()
                          .subtract(1, 'month')
                          .endOf('month'),
                        selections: [],
                      },
                      this.filterData
                    )
                  }
                >
                  Edellinen kuukausi
                </Button>
              </Col>
              <Col>
                <Label for='showseasonals'>Kausilaskutettavat</Label>{' '}
                <Switch
                  defaultValue={this.state.showseasonals}
                  onColor='primary'
                  offColor='primary'
                  id='showseasonals'
                  name='showseasonals'
                  onChange={this.showSeasonBilled}
                />{' '}
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  color='info'
                  size='sm'
                  onClick={() => this.toggleSelectAll()}
                  disabled={
                    (this.state.venues &&
                      this.state.venues.length > 1 &&
                      !this.state.selectedVenue) ||
                    this.state.invoicings.length === 0
                  }
                >
                  {this.state.selectionModel.length ===
                  this.state.invoicings.length
                    ? this.state.selectionModel.length > 0
                      ? 'Poista valinnat'
                      : 'Valitse ...'
                    : 'Valitse kaikki'}
                </Button>
              </Col>
              <Col>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.filters.recurrencies}
                      onChange={this.handleFilterChange}
                      name='recurrencies'
                      color='primary'
                    />
                  }
                  label='Vakiovuorot'
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.filters.singles}
                      onChange={this.handleFilterChange}
                      name='singles'
                      color='primary'
                    />
                  }
                  label='Irtovuorot'
                />
              </Col>
              {this.state.alltags && this.state.alltags.length > 0 ? (
                <>
                  <Col className='text-right'>
                    <Typography
                      id='multiple-chip-label'
                      component={'span'}
                      style={{ marginRight: 10 }}
                    >
                      Tagi filtterit
                    </Typography>
                    <FormControl style={{ margin: 1, minWidth: 150 }}>
                      {/* <InputLabel id="multiple-chip-label">Tagit</InputLabel> */}
                      <Select
                        labelId='multiple-chip-label'
                        id='mutiple-chip'
                        multiple
                        value={this.state.filters.tags.tags}
                        onChange={this.handleTagFilterChange}
                        input={<Input id='select-multiple-chip' />}
                        renderValue={(selected) => selected.join(', ')}
                      >
                        {this.state.alltags.map((name) => (
                          <MenuItem key={name} value={name}>
                            <Checkbox
                              checked={
                                this.state.filters.tags.tags.indexOf(name) > -1
                              }
                            />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Col>
                  <Col>
                    <FormControl component='fieldset'>
                      <RadioGroup
                        row
                        aria-label='position'
                        name='position'
                        value={this.state.filters.tags.mode}
                        onChange={this.handleTagfilterModeChange}
                      >
                        <FormControlLabel
                          value='inc'
                          control={<Radio color='primary' />}
                          label='Mukaan'
                          labelPlacement='left'
                        />
                        <FormControlLabel
                          value='exc'
                          control={<Radio color='primary' />}
                          label='Pois'
                          labelPlacement='left'
                        />
                      </RadioGroup>
                    </FormControl>
                  </Col>
                </>
              ) : null}

              <Col className='text-right'>
                <Button
                  color='success'
                  size='sm'
                  onClick={(el) => {
                    this.checkSaldos()
                  }}
                  disabled={
                    this.state.venues &&
                    this.state.venues.length > 1 &&
                    !this.state.selectedVenue
                  }
                >
                  Tarkista saldot
                </Button>
              </Col>
            </Row>
            {this.state.selectionModel.length > 1 ? (
              <Row>
                <Col>
                  <Button
                    color='info'
                    size='sm'
                    onClick={(el) => {
                      this.toggleExternalBatchDialog()
                    }}
                  >
                    Muodosta valituista laskut
                  </Button>
                  <Button
                    color='warning'
                    size='sm'
                    onClick={(el) => {
                      this.toggleExternalCustomerSelectDialog()
                    }}
                  >
                    Muodosta valituista yksi lasku
                  </Button>
                </Col>
              </Row>
            ) : null}

            <Row>
              <Col md='12'>
                <div
                  style={{
                    display: 'flex',
                    // minHeight: 'calc(100vh - 200px)',
                    backgroundColor: '#FFFFFF',
                  }}
                >
                  <div style={{ flexGrow: 1 }}>
                    <DataGrid
                      rows={this.state.invoicings || []}
                      columns={this.getColumns(nameWidth)}
                      pageSize={this.state.pageSize}
                      rowsPerPageOptions={[50, 100, 200]}
                      filterable
                      pagination
                      autoHeight
                      checkboxSelection
                      disableSelectionOnClick
                      onSelectionModelChange={(newSelectionModel) => {
                        this.setState({ selectionModel: newSelectionModel }) //setSelectionModel(newSelectionModel);
                      }}
                      selectionModel={this.state.selectionModel}
                      onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                      // defaultFilterMethod={(filter, row) => filterCaseInsensitive(filter, row)}
                      components={{
                        Toolbar: CustomToolbar
                      }}
                      componentsProps={{
                        toolbar: { total: this.state.totalSum }
                      }}
                      localeText={{
                        toolbarDensity: 'Riviväli',
                        toolbarDensityLabel: 'Riviväli',
                        toolbarDensityCompact: 'Tiheä',
                        toolbarDensityStandard: 'Normaali',
                        toolbarDensityComfortable: 'Väljä',
                        toolbarExport: 'Lataa / Tulosta',
                        toolbarExportLabel: 'Lataa / Tulosta',
                        toolbarExportCSV: 'Lataa CSV',
                        toolbarExportPDF: 'Lataa PDF',
                        toolbarExportPrint: 'Tulosta',
                        toolbarFilters: 'Suodata',
                        toolbarFiltersLabel: 'Suodata',
                        toolbarColumns: 'Sarakkeet',
                        toolbarColumnsLabel: 'Sarakkeet',
                      }}
                      onStateChange={(state) => {
                        // if (this.props.countTotal) {
                          console.log('onStateChange', state)
                          this.countTotal(state)
                        // }
                        // console.log('onStateChange2', state)
                      }}
                      // onStateChange={(state) => {
                      //   const visibleRows = state.filter.visibleRowsLookup
                      //   let visibleItems = []
                      //   for (const [id, value] of Object.entries(visibleRows)) {
                      //     if (value === true) {
                      //       visibleItems.push(id)
                      //     }
                      //   }
                      //   const res = this.state.invoicings.filter((item) =>
                      //     visibleItems.includes(item.id)
                      //   )
                      //   const total = res
                      //     .map((item) => item.unpaid)
                      //     .reduce((a, b) => a + b, 0)
                      //   console.log(total)
                      //   // this.setState({ total });
                      // }}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            {this.state.alert}
            <Dialog
              fullWidth={true}
              maxWidth={'xl'}
              open={this.state.invoicingDialogOpen}
              onClose={this.closeRowDetails}
              aria-labelledby='max-width-dialog-title'
              style={{ zIndex: '1100' }}
            >
              <DialogTitle id='max-width-dialog-title'>
                {this.state?.selectedResource?.user
                  ? this.state.selectedResource.user
                  : null}{' '}
                {this.state.startDate.format('D.M.YYYY') +
                  ' - ' +
                  this.state.endDate.format('D.M.YYYY')}{' '}
              </DialogTitle>
              <DialogContent>
                {/* {this.state.selectedResource ? this.renderRows() : null} */}
                <InvoicingDraft selectedResource={this.state.selectedResource} venues={this.state.venues} handleSelectReservation={this.handleSelectReservation} invoicetags={this.state.invoicetags} />
              </DialogContent>
              <DialogActions>
                <Button onClick={this.closeRowDetails} color='secondary'>
                  Sulje
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              fullWidth={true}
              maxWidth={'sm'}
              open={this.state.externalBatchConfirmationOpen}
              onClose={this.toggleExternalBatchDialog}
              aria-labelledby='max-width-dialog-title'
              style={{ zIndex: '1000' }}
            >
              <DialogTitle id='max-width-dialog-title'>
                Vahvista lähetys
              </DialogTitle>
              <DialogContent>{this.renderInvoiceExtras()}</DialogContent>
              <DialogActions>
                <Button
                  disabled={!this.state.billingDate || this.state.actionWait}
                  onClick={() =>
                    this.confirmedBatchAction(
                      'external',
                      true,
                      this.toggleExternalBatchDialog
                    )
                  }
                  color='primary'
                >
                  {this.state.actionWait ? <Spinner size='sm' /> : null}{' '}
                  Muodosta lasku
                </Button>
                <Button
                  onClick={this.toggleExternalBatchDialog}
                  color='secondary'
                >
                  Peruuta
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              fullWidth={true}
              maxWidth={'sm'}
              open={this.state.externalConfirmationOpen}
              onClose={this.toggleExternalDialog}
              aria-labelledby='max-width-dialog-title'
              style={{ zIndex: '1000' }}
            >
              <DialogTitle id='max-width-dialog-title'>
                Vahvista lähetys
              </DialogTitle>
              <DialogContent>{this.renderInvoiceExtras()}</DialogContent>
              <DialogActions>
                <Button
                  disabled={!this.state.billingDate || this.state.actionWait}
                  onClick={() =>
                    this.confirmedCreateAction(
                      'external',
                      this.toggleExternalDialog
                    )
                  }
                  color='primary'
                >
                  {this.state.actionWait ? <Spinner size='sm' /> : null}{' '}
                  Muodosta lasku
                </Button>
                <Button onClick={this.toggleExternalDialog} color='secondary'>
                  Peruuta
                </Button>
              </DialogActions>
            </Dialog>
            {/* <Dialog
              fullWidth={true}
              maxWidth={'sm'}
              open={this.state.finvoiceConfirmationOpen}
              onClose={this.toggleFinvoiceDialog}
              aria-labelledby='max-width-dialog-title'
              style={{ zIndex: '1000' }}
            >
              <DialogTitle id='max-width-dialog-title'>Lataa</DialogTitle>
              <DialogContent>{this.renderInvoiceExtras()}</DialogContent>
              <DialogActions>
                <Button
                  disabled={!this.state.billingDate || this.state.actionWait}
                  onClick={() => this.confirmedAction('finvoice', true, this.toggleFinvoiceDialog)}
                  color='primary'
                >
                  {this.state.actionWait ? <Spinner size='sm' /> : null} Lataa ja merkkaa laskutetuksi
                </Button>
                <Button
                  disabled={!this.state.billingDate || this.state.actionWait}
                  onClick={() => this.confirmedAction('finvoice', false, this.toggleFinvoiceDialog)}
                  color='seconday'
                >
                  {this.state.actionWait ? <Spinner size='sm' /> : null} Lataa
                </Button>
                <Button onClick={this.toggleFinvoiceDialog} color='secondary'>
                  Peruuta
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              fullWidth={true}
              maxWidth={'sm'}
              open={this.state.emailInvoiceConfirmationOpen}
              onClose={this.toggleEmailInvoiceDialog}
              aria-labelledby='max-width-dialog-title'
              style={{ zIndex: '1000' }}
            >
              <DialogTitle id='max-width-dialog-title'>Vahvista lähetys</DialogTitle>
              <DialogContent>{this.renderInvoiceExtras()}</DialogContent>
              <DialogActions>
                <Button
                  disabled={!this.state.billingDate || this.state.actionWait}
                  onClick={() => this.confirmedAction('email', true, this.toggleEmailInvoiceDialog)}
                  color='primary'
                >
                  {this.state.actionWait ? <Spinner size='sm' /> : null} Lähetä ja merkkaa laskutetuksi
                </Button>
                <Button onClick={this.toggleEmailInvoiceDialog} color='secondary'>
                  Peruuta
                </Button>
              </DialogActions>
            </Dialog> 
            <Dialog
              fullWidth={true}
              maxWidth={'sm'}
              open={this.state.onlineConfirmationOpen}
              onClose={this.toggleOnlineDialog}
              aria-labelledby='max-width-dialog-title'
              style={{ zIndex: '1000' }}
            >
              <DialogTitle id='max-width-dialog-title'>Vahvista lähetys</DialogTitle>
              <DialogContent>Lähetä käyttäjälle muistutus maksaa vuorot online maksuna.</DialogContent>
              <DialogActions>
                <Button onClick={this.toggleOnlineDialog} color='primary'>
                  Lähetä
                </Button>
                <Button onClick={this.toggleOnlineDialog} color='primary'>
                  Sulje
                </Button>
              </DialogActions>
            </Dialog> */}
            <InvoiceCustomerSelectDialog
              toggle={this.toggleExternalCustomerSelectDialog}
              onSubmit={this.confirmCombinedInvoiceAction}
              isOpen={this.state.externalCustomerSelectConfirmationOpen}
            />
            <SaldodividendDialog
              isOpen={this.state.saldodividendDialogOpen}
              toggle={this.toggleSaldodividendDialog}
              rows={this.state.selectedsaldo.rows}
              saldoSum={this.state.selectedsaldo.sum}
              onSubmit={this.addSaldoPayments}
              processing={this.state.actionWait}
            />
            <ReservationModal
              isOpen={this.state.reservationModalOpen}
              toggle={this.toggleReservationModal}
              resource={this.state.selectedReservation}
              tags={null}
              {...this.props}
            />
          </div>
        </>
      )
    }
  }
}

export default withFirebase(InvoicingContainer)
